export const itTranslations = {
  //Italian
  it: {
    metadata: {
      debugDisplayName: 'Italian',
    },
    translations: {
      delete: 'Elimina',
      cancel: 'Annulla',
      add: 'Aggiunga',
      clear: 'Cancella',
      rotate: 'Gira',
      wizard: {
        nextStep: 'Passo successivo',
        previousStep: 'Passo precedente',
        submit: 'Invia',
      },
      validationPopup: {
        heading: 'Ci sono errori di convalida',
        message: 'Il form contiene errori di convalida. Per favore correggere i campi non validi.',
      },
      submissionFailedPopup: {
        heading: 'Invio fallito.',
        message: "Si è verificato un errore durante l'invio del modulo. Per favore riprova.",
      },
      booleanButtons: {
        yes: 'Si',
        no: 'No',
      },
      otherControl: {
        other: 'Altro',
      },
      splitDate: {
        day: 'Giorno',
        month: 'Mese',
        year: 'Anno',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'Il modulo è stato disabilitato probabilmente a causa dei dati non corretti',
          dialog: {
            title: 'Il modulo si è disabilitato',
            content: 'Il modulo è stato disabilitato probabilmente a causa dei dati non corretti',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Nessuna annotazione registrata',
      },
      repeat: {
        deleteRow: 'Elimina la riga',
        deleteRowConfirmation: {
          title: 'Conferma eliminazione',
          message: 'Conferma eliminazione? Non puoi annullare la modifica',
        },
      },
    },
  },
};
