import { Instance } from '@airelogic/xpath';
import Enumerable from 'linq';
import { Element } from 'slimdom';
import Bind from '../Components/Bind';
import BindStore from '../Components/BindStore';
import BuildingBlock from '../Components/BuildingBlock';
import Cell from '../Components/Cell';
import Grid from '../Components/Grid';
import { InstanceStore } from '../Components/InstanceStore';
import MasterChild from '../Components/MasterChild';
import Repeat from '../Components/Repeat';
import Row from '../Components/Row';
import Section from '../Components/Section';
import { IBuildingBlockDefinition } from '../Definitions/IBuildingBlockDefinition';
import { ICellDefinition } from '../Definitions/ICellDefinition';
import { IGridDefinition } from '../Definitions/IGridDefinition';
import { IMasterChildDefinition } from '../Definitions/IMasterChildDefinition';
import { IRepeatDefinition } from '../Definitions/IRepeatDefinition';
import { ISectionDefinition } from '../Definitions/ISectionDefinition';
import { assertNever } from '../Utils';
import { tryResolveBindToElement } from './BindConverter';
import { createControl } from './ControlFactory';

export const convertBuildingBlocks = (
  instanceStore: InstanceStore,
  bindStore: BindStore,
  buildingBlocks: IBuildingBlockDefinition[],
): Enumerable.IDictionary<string, BuildingBlock> => {
  return Enumerable.from(buildingBlocks).toDictionary(
    (definition) => definition.key,
    (definition) => convertBlock(instanceStore.resolve(definition.key), bindStore, definition),
  );
};

const convertBlock = (
  instance: Instance,
  bindStore: BindStore,
  definition: IBuildingBlockDefinition,
): BuildingBlock => {
  const sections = definition.layout.map((section) => convertSection(instance, bindStore, section));

  const bind = bindStore.getBindById(`${definition.key}-fr-form-binds`);

  bind.attachToElement(instance.xml.firstElementChild!);

  return new BuildingBlock(definition.key, bind, sections, definition.viewMode);
};

const convertSection = (
  instance: Instance,
  bindStore: BindStore,
  sectionDefinition: ISectionDefinition,
): Section => {
  const sectionBind = bindStore.getBindById(sectionDefinition.id);

  attachBindToElement(instance, sectionBind);

  const grids: (Grid | Repeat | MasterChild)[] = sectionDefinition.grids.map((grid) => {
    switch (grid.type) {
      case 'repeat':
        return createRepeat(instance, bindStore, grid);
      case 'grid':
        return createGrid(instance, bindStore, grid);
      case 'master-child':
        return createMasterChild(instance, bindStore, grid);
      default:
        return assertNever(grid);
    }
  });

  return new Section(sectionDefinition, sectionBind, grids);
};

const createGrid = (
  instance: Instance,
  bindStore: BindStore,
  definition: IGridDefinition,
): Grid => {
  const convertedRows = definition.rows.map(
    (rowDefinition) =>
      new Row(
        rowDefinition,
        rowDefinition.cells.map((cell) => createCell(instance, bindStore, cell)),
      ),
  );
  return new Grid(convertedRows);
};

const createCell = (
  instance: Instance,
  bindStore: BindStore,
  definition: ICellDefinition,
): Cell => {
  if (definition.control) {
    const resolvedBind = bindStore.getBindById(definition.control.id);
    const element = attachBindToElement(instance, resolvedBind);

    if (definition.control.type === 'secret') {
      element.setAttribute('f4h:secret', 'true');
    }

    return new Cell(definition, createControl(definition.control, resolvedBind));
  } else {
    return new Cell(definition);
  }
};

const attachBindToElement = (instance: Instance, bind: Bind): Element => {
  const resolvedElement = tryResolveBindToElement(instance, bind.fullRef);

  if (resolvedElement == null) {
    throw Error('Unable to find element ' + bind.fullRef);
  }

  bind.attachToElement(resolvedElement);

  return resolvedElement;
};

const createRepeat = (
  instance: Instance,
  bindStore: BindStore,
  definition: IRepeatDefinition,
): Repeat => {
  const resolvedBind = bindStore.getBindById(definition.id);
  const repeatElement = attachBindToElement(instance, resolvedBind);
  return new Repeat(definition, resolvedBind, repeatElement, bindStore);
};

const createMasterChild = (
  instance: Instance,
  bindStore: BindStore,
  definition: IMasterChildDefinition,
): MasterChild => {
  const resolvedBind = bindStore.getBindById(definition.id);
  const repeatElement = attachBindToElement(instance, resolvedBind);
  return new MasterChild(definition, resolvedBind, repeatElement, bindStore);
};
