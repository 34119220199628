import { PropsWithChildren } from 'react';
import { makeStyles } from '../../Themes/ThemeProvider';

const useContainerStyle = makeStyles()({
  structureContainer: {
    position: 'relative',
    margin: '0 auto',
    width: '95%',
    minWidth: '15em',
    maxWidth: '1008px',
  },
});

const Container = ({ children }: PropsWithChildren<unknown>) => {
  const {
    classes: { structureContainer },
  } = useContainerStyle();

  return <div className={structureContainer}>{children}</div>;
};

export default Container;
