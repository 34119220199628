import { i18n } from '.';

export function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

export function isDocumentRtl() {
  const queryParams = new URLSearchParams(window.location.search);
  const demoDir = queryParams.get('dir');
  return (i18n.dir(i18n.language) === 'rtl' || demoDir === 'rtl') && demoDir !== 'ltr';
}
