export const hrTranslations = {
  //Croatian
  hr: {
    metadata: {
      debugDisplayName: 'Croatian',
    },
    translations: {
      wizard: {
        nextStep: 'Sljedeći korak',
        previousStep: 'Prethodni korak',
        submit: 'Podnijeti',
      },
      validationPopup: {
        heading: 'Postoje pogreške u validaciji',
        message: 'Obrazac sadrži pogreške u provjeri valjanosti. Ispravite nevaljana polja.',
      },
      submissionFailedPopup: {
        heading: 'Slanje nije uspjelo',
        message: 'Došlo je do pogreške prilikom slanja obrasca. Molim te pokušaj ponovno.',
      },
      booleanButtons: {
        yes: 'Da',
        no: 'Ne',
      },
      otherControl: {
        other: 'Ostalo',
      },
      splitDate: {
        day: 'Dan',
        month: 'Mjesec',
        year: 'Godina',
      },
    },
  },
};
