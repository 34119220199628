import { IRowDefinition } from '../Definitions/IRowDefinition';
import Cell from './Cell';

export default class Row {
  constructor(
    private readonly definition: IRowDefinition,
    public readonly cells: Cell[],
  ) {}

  public get totalCellSpan(): number {
    return this.definition.totalCellSpan;
  }
}
