import { makeStyles } from 'tss-react/mui';
import Spinner from './Spinner';

interface IProps {
  fullscreen: boolean;
  label: string;
}

const useStyles = makeStyles()({
  root: {
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    opacity: 0.4,
    '& img': {
      height: '200px',
      width: '200px',
      borderRadius: '5px',
    },
  },
  content: {
    margin: 'auto',
    textAlign: 'center',
  },
  displayFullScreen: {
    position: 'fixed',
  },
});

const SpinnerWithText = ({ fullscreen, label }: IProps) => {
  const {
    classes: { root, content, displayFullScreen },
    cx,
  } = useStyles();

  return (
    <div className={cx(root, { [displayFullScreen]: fullscreen })}>
      <div className={content}>
        <Spinner />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default SpinnerWithText;
