import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '../../translations';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <select onChange={changeLanguage} onBlur={changeLanguage} value={i18n.language}>
      {Object.keys(translations).map((lang) => (
        <option key={lang} value={lang}>
          {i18n.getResource(lang, 'metadata', 'debugDisplayName')}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelector;
