export const esTranslations = {
  //Spanish
  es: {
    metadata: {
      debugDisplayName: 'Spanish',
    },
    translations: {
      delete: 'Eliminar',
      cancel: 'Cancelar',
      add: 'Añadir',
      clear: 'Borrar',
      rotate: 'Girar',
      wizard: {
        nextStep: 'Próximo paso',
        previousStep: 'Paso anterior',
        submit: 'Enviar',
      },
      validationPopup: {
        heading: 'Error de Validación',
        message:
          'El formulario contiene errores de validación. Por favor compruebe los campos inválidos.',
      },
      submissionFailedPopup: {
        heading: 'Envío Fallido',
        message: 'Se produjo un error al enviar el formulario. Inténtalo de nuevo.',
      },
      booleanButtons: {
        yes: 'Si',
        no: 'No',
      },
      otherControl: {
        other: 'Otro',
      },
      splitDate: {
        day: 'Día',
        month: 'Mes',
        year: 'Año',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'Esta forma ha sido retirada. La información que contiene puede no ser exacta',
          dialog: {
            title: 'Forma retirada',
            content: 'Esta forma ha sido retirada. La información que contiene puede no ser exacta',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'No se han registrado las anotaciones',
      },
      repeat: {
        deleteRow: 'Eliminar la fila',
        deleteRowConfirmation: {
          title: 'Confirmar eliminación',
          message: '¿Estas seguro que quieres eliminar la fila? Esta acción no se puede deshacer',
        },
      },
    },
  },
};
