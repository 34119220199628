import { IInstanceResolver, Instance } from '@airelogic/xpath';
import Enumerable from 'linq';

export interface InstanceStore {
  instances: Instance[];
  resolve: IInstanceResolver;
}

const createInstanceStore = (instances: Instance[]) => {
  const clonedInstances = instances.slice();

  const resolve = (id: string): Instance => {
    const resolvedInstance = Enumerable.from(instances).singleOrDefault(
      (instance) => instance.id === id,
    );
    if (resolvedInstance) {
      return resolvedInstance;
    }

    throw Error('Unable to find instance with id ' + id);
  };

  return {
    instances: clonedInstances,
    resolve,
  };
};

export default createInstanceStore;
