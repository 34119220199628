import { evaluateXPathToFirstNode } from '@airelogic/fontoxpath';
import {
  IXPathEvaluator,
  Instance,
  InvalidXPathError,
  XPathReferenceParser,
} from '@airelogic/xpath';
import Enumerable from 'linq';
import { Element } from 'slimdom';
import Bind from '../Components/Bind';
import BindStore from '../Components/BindStore';
import { InstanceStore } from '../Components/InstanceStore';
import { IBindDefinition } from '../Definitions/IBindDefinition';

interface IBuildingBlockDefinition {
  key: string;
  binds: IBindDefinition[];
}

export const convertBinds = (
  instanceStore: InstanceStore,
  xPathEvaluator: IXPathEvaluator,
  buildingBlocks: IBuildingBlockDefinition[],
): BindStore => {
  const bindDefinitionsByBlock = Enumerable.from(buildingBlocks)
    .groupBy((block) => block.key)
    .select((group) => {
      return {
        key: group.key(),
        bindDefinitions: group.selectMany((block) => block.binds),
      };
    });

  const bindStore = new BindStore();
  const binds = [];

  for (const definitionsByBlock of bindDefinitionsByBlock.toArray()) {
    const blockInstance = instanceStore.resolve(definitionsByBlock.key);
    for (const bindDefinition of definitionsByBlock.bindDefinitions.toArray()) {
      evaluateBindDefinitionForErrors(
        xPathEvaluator,
        blockInstance,
        definitionsByBlock.key,
        bindDefinition,
      );
      binds.push(
        new Bind(
          xPathEvaluator,
          bindStore,
          bindDefinition,
          getReferencedBindIds(definitionsByBlock.key, bindDefinition),
        ),
      );
    }
  }
  bindStore.addBinds(binds);

  return bindStore;
};

const getReferencedBindIds = (defaultInstanceKey: string, bind: IBindDefinition): string[] => {
  const parser = new XPathReferenceParser(defaultInstanceKey);
  const references: string[] = new Array<string>();
  references.push(...parser.parse(bind.required.xpath));

  if (bind.readOnlyXPath) {
    references.push(...parser.parse(bind.readOnlyXPath));
  }

  if (bind.relevanceXPath) {
    references.push(...parser.parse(bind.relevanceXPath));
  }

  if (bind.calculatedValueXPath) {
    references.push(...parser.parse(bind.calculatedValueXPath));
  }

  if (bind.constraints) {
    for (const constraint of bind.constraints) {
      references.push(...parser.parse(constraint.xpath));
    }
  }

  if (bind.itemsetFilterXPath) {
    references.push(...parser.parse(bind.itemsetFilterXPath));
  }

  if (bind.valuesOfInterest) {
    for (const valueOfInterest of bind.valuesOfInterest) {
      references.push(...parser.parse(valueOfInterest.xPath));
    }
  }

  return references;
};

export const tryResolveBindToElement = (
  instance: Instance,
  absoluteBindRef: string,
): Element | null => {
  return evaluateXPathToFirstNode<Element>(absoluteBindRef, instance.xml);
};

const evaluateBindDefinitionForErrors = (
  xPathEvaluator: IXPathEvaluator,
  instance: Instance,
  buildingBlockKey: string,
  bindDefinition: IBindDefinition,
) => {
  //Attempt to evaluate any xpaths so errors are produced when the form is first loaded and not when the xpath eventually may get executed.
  //XPaths should not mutate the data.

  const element = tryResolveBindToElement(instance, bindDefinition.fullRef);

  //If there's no element (empty repeat) - just skip for now. (Hotfix)
  if (element) {
    const xPathsToEvaluate = new Array<{ xPath: string; bindLocation: string }>();

    xPathsToEvaluate.push({ xPath: bindDefinition.required.xpath, bindLocation: 'required' });

    if (bindDefinition.readOnlyXPath) {
      xPathsToEvaluate.push({ xPath: bindDefinition.readOnlyXPath, bindLocation: 'read only' });
    }

    if (bindDefinition.relevanceXPath) {
      xPathsToEvaluate.push({ xPath: bindDefinition.relevanceXPath, bindLocation: 'visibility' });
    }

    if (bindDefinition.calculatedValueXPath) {
      xPathsToEvaluate.push({
        xPath: bindDefinition.calculatedValueXPath,
        bindLocation: 'calculated value',
      });
    }

    if (bindDefinition.constraints) {
      for (const constraint of bindDefinition.constraints) {
        xPathsToEvaluate.push({ xPath: constraint.xpath, bindLocation: 'constraint' });
      }
    }

    if (bindDefinition.itemsetFilterXPath) {
      xPathsToEvaluate.push({
        xPath: bindDefinition.itemsetFilterXPath,
        bindLocation: 'item choice xpath',
      });
    }

    if (bindDefinition.valuesOfInterest) {
      for (const valueOfInterest of bindDefinition.valuesOfInterest) {
        xPathsToEvaluate.push({ xPath: valueOfInterest.xPath, bindLocation: 'valueOfInterest' });
      }
    }

    for (const xPathToEvaluate of xPathsToEvaluate) {
      try {
        xPathEvaluator.evaluateXPath(xPathToEvaluate.xPath, element);
      } catch (error) {
        if (error instanceof Error) {
          //Ignore casting errors as the fields are empty
          if (error.message.startsWith('FORG0001')) {
            return;
          }
          throw new InvalidXPathError(
            `There was an error on the ${
              xPathToEvaluate.bindLocation
            } of control '${bindDefinition.id.replace(buildingBlockKey + '-', '')}' ${
              buildingBlockKey === 'preview-form' ? '' : ` in building block '${buildingBlockKey}'.`
            } ${error.message} XPath: ${xPathToEvaluate.xPath}`,
          );
        }
      }
    }
  }
};
