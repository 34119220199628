import { sanitize } from '@airelogic/sanitize-xml-string';
import { ITypeDefinition } from './TypeDefinitionFactory';

export default class StringDefinition implements ITypeDefinition {
  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return sanitize(value, '');
  }

  isValid(value: string) {
    return true;
  }
}
