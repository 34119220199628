import { evaluateXPath, evaluateXPathToStrings, parseScript } from '@airelogic/fontoxpath';
import uniq from 'lodash/uniq';
import { Document, Element } from 'slimdom';

export default class XPathReferenceParser {
  constructor(private readonly defaultInstanceKey: string) {}

  public parse(xPath: string): string[] {
    const document = new Document();
    const xqueryx = parseScript<Element>(
      xPath,
      {
        language: evaluateXPath.XPATH_3_1_LANGUAGE,
      },
      document,
    );

    document.appendChild(xqueryx);

    const results = evaluateXPathToStrings(
      `//xqx:stepExpr[xqx:xpathAxis != 'attribute' and xqx:nameTest]/concat(
        if (ancestor-or-self::xqx:stepExpr[preceding-sibling::xqx:stepExpr/xqx:filterExpr/xqx:functionCallExpr[xqx:functionName = 'instance']]) 
        then concat(ancestor-or-self::xqx:stepExpr[preceding-sibling::xqx:stepExpr/xqx:filterExpr/xqx:functionCallExpr[xqx:functionName = 'instance']][1]/preceding-sibling::xqx:stepExpr/xqx:filterExpr/xqx:functionCallExpr[xqx:functionName = 'instance']/xqx:arguments/xqx:stringConstantExpr/xqx:value, '-')
        else concat($defaultKey, '-'), xqx:nameTest)`,
      document,
      null,
      {
        defaultKey: this.defaultInstanceKey,
      },
    ).filter(
      (x) => x.startsWith('form-context') === false, //Filter out form-context nodes
    );
    return uniq(results);
  }
}
