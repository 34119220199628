import { Node } from 'slimdom';
import { Instance } from '../Instance';
import { registerCUIFunctions } from './CUIFunctions';
import { registerCustomFunctions } from './CustomFunctions';
import { registerXFormsFunctions } from './XFormFunctions';

export const registerAllCustomFunctions = (
  xpathRegister: IXPathRegister,
  instanceResolver: () => IInstanceResolver,
  currentNodeResolver: ICurrentNodeResolver,
) => {
  registerXFormsFunctions(xpathRegister, instanceResolver, currentNodeResolver);
  registerCUIFunctions(xpathRegister);
  registerCustomFunctions(xpathRegister);
};

export type IXPathRegister = (
  name: string,
  params: string[],
  result: string,
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
) => void;

export type IInstanceResolver = (id: string) => Instance;

export type ICurrentNodeResolver = () => Node | null;
