export const plTranslations = {
  //Polish
  pl: {
    metadata: {
      debugDisplayName: 'Polish',
    },
    translations: {
      delete: 'Usuń',
      cancel: 'Anuluj',
      add: 'Dodaj',
      clear: 'Wyczyść',
      rotate: 'Obróć',
      noOptionsFound: 'Nie znaleziono odpowiednich opcji',
      wizard: {
        nextStep: 'Do przodu',
        previousStep: 'Cofnij',
        submit: 'Wyślij',
      },
      validationPopup: {
        heading: 'Błąd zatwierdzenia',
        message: 'Formularz zawiera błąd zatwierdzenia. Proszę poprawić źle wypełnione pola.',
      },
      submissionFailedPopup: {
        heading: 'Nieudana próba wysłania',
        message:
          'Wystąpił błąd podczas wysyłania formularza. Sprawdź połączenie i spróbuj ponownie.',
      },
      booleanButtons: {
        yes: 'Tak',
        no: 'Nie',
      },
      otherControl: {
        other: 'Inne',
      },
      splitDate: {
        day: 'Dzień',
        month: 'Miesiąc',
        year: 'Rok',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'Formularz wycofany z użytku. Informacje, które zawiera mogą nie być właściwe.',
          dialog: {
            title: 'Wycofanie formularza',
            content:
              'Formularz wycofany z użytku. Informacje, które zawiera mogą nie być właściwe.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Brak adnotacji',
      },
      repeat: {
        deleteRow: 'Usuń wiersz',
        deleteRowConfirmation: {
          title: 'Potwierdź usunięcie',
          message: 'Jesteś pewien, że chcesz usunąć cały wiersz? Czynność nie może być cofnięta.',
        },
      },
    },
  },
};
