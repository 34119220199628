export const cyTranslations = {
  //Welsh
  cy: {
    metadata: {
      debugDisplayName: 'Welsh',
    },
    translations: {
      delete: 'Dileu',
      cancel: 'Canslo',
      add: 'Ychwanegu',
      clear: 'Canslo',
      rotate: 'Cylchdroi',
      wizard: {
        nextStep: 'Cam nesaf',
        previousStep: 'Cam blaenorol',
        submit: 'Cyflwyno',
      },
      validationPopup: {
        heading: 'Gwallau dilysu',
        message: 'Cywirwch unrhyw wallau.',
      },
      submissionFailedPopup: {
        heading: 'Methodd y cyflwyniad',
        message: 'Digyddodd gwall.',
      },
      booleanButtons: {
        yes: 'Oes',
        no: 'Nac ydw',
      },
      otherControl: {
        other: 'Arall',
      },
      splitDate: {
        day: 'Dydd',
        month: 'Mis',
        year: 'Blwyddyn',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: "Mae'r ffurflen hon wedi'i thynnu'n ol.",
          dialog: {
            title: "Ffurflen wedi'i thynnu'n ol",
            content: "Mae'r ffurflen hon wedi'i thynnu'n ol.",
          },
        },
      },
      annotatedImage: {
        noAnnotations: "Dim anodiadau wedi'u cofnodi",
      },
      repeat: {
        deleteRow: 'Dileu rhes',
        deleteRowConfirmation: {
          title: 'Cadarnhau dileu',
          message: "Ydych chi'n siwr eich bod am ddileu'r rhes?  Ni eilir dadwneud y weithred.",
        },
      },
    },
  },
};
