import { getContrastRatio } from '@mui/material';
import { CSSObject } from 'tss-react';

export type ThemeName = 'Custom' | 'GDS' | 'Default' | 'DefaultCompact';

export const getTextColor = (backgroundColor: string, requestedTextColor: string): string => {
  if (getContrastRatio(requestedTextColor, backgroundColor) > 4.5) {
    return requestedTextColor;
  } else if (getContrastRatio('#fff', backgroundColor) > 4.5) {
    return '#fff';
  } else {
    return '#000';
  }
};

export type SectionStrokeStyle = {
  section?: CSSObject;
  _content?: CSSObject;
  heading?: CSSObject;
};

export interface FormTheme {
  name: ThemeName;
  primaryColor: string;
  primaryTextColor: string;
  errorColor: string;
  fontFamily: string; //If we decide to change font also change it in the relevant form.css file.
  headerFlexDirection: 'column' | 'column-reverse';
  headerMargin: string;
  headerBackgroundColor: string;
  headerTitleTextColor: string;
  headerTitleBorder: string;
  headerTitleBorderWidth: string;
  islandTextColor: string;
  islandTextSecondaryColor: string;
  islandBackgroundColor: string;
  islandBorderTop: string;
  islandHeaderFontWeight: number | string;
  stepSpacingMargin: string;
  explanatoryTextPadding: string;
  explanatoryTextMargin: string | number;
  explanatoryTextBorder: string;
  explanatoryTextBorderWidth: number | string;
  badgeBorderRadius: number | string;
  badgeSuccessBackgroundColor: string;
  badgeSuccessTextColor: string;
  badgeDangerBackgroundColor: string;
  badgeDangerTextColor: string;
  formGroupMargin: number | string;
  formGroupPadding: number | string;
  formGroupInvalidBorder: string;
  formGroupInvalidBorderWidth: number | string;
  formGroupInvalidLeft: number | string;
  formGroupInvalidPadding: number | string;
  formControlBorderRadius: number | string;
  formControlBorderWidth: number | string;
  formControlBorderColor: string;
  formControlFocusedOutline: string;
  formControlFocusedOutlineOffset: number | string;
  formControlFocusedBorderColor: string;
  formControlFocusedBoxShadow: string;
  formControlInvalidBorder: string;
  formControlInvalidBorderWidth: number | string;
  selectOptionDisabledBackgroundColor: string;
  selectOptionDisabledTextColor: string;
  selectOptionSelectedBackgroundColor: string;
  selectOptionSelectedTextColor: string;
  selectOptionFocusedBackgroundColor: string;
  selectOptionFocusedTextColor: string;
  selectOptionUnfocusedBackgroundColor: string;
  selectOptionUnfocusedTextColor: string;
  radioButtonTwoItemGroupFlexDirection: 'row' | 'column';
  checkboxSize: string;
  checkboxPadding: number | string;
  checkboxLabelPadding: number | string;
  checkboxFillColor: string;
  checkboxFocusBoxShadow: string;
  checkboxCheckedFillColor: string;
  checkboxSelectedLabelBackgroundColor: string;
  buttonBorderRadius: number | string;
  defaultButtonColor: string;
  defaultButtonTextColor: string;
  defaultButtonBorder: string;
  defaultButtonBoxShadow: string;
  defaultButtonHoverColor: string;
  defaultButtonFocusedBoxShadow: string;
  defaultButtonDisabledColor: string;
  defaultButtonDisabledBorder: string;
  selectedButtonFocusedBoxShadow: string;
  primaryButtonColor: string;
  primaryButtonTextColor: string;
  primaryButtonBoxShadow: string;
  primaryButtonHoverColor: string;
  primaryButtonFocusedBoxShadow: string;
  primaryButtonFocusedColor: string;
  primaryButtonFocusedTextColor: string;
  primaryButtonFocusedBorder: string;
  primaryBorderedButtonColor?: string;
  secondaryButtonColor: string;
  secondaryButtonTextColor: string;
  secondaryButtonBoxShadow: string;
  secondaryButtonHoverColor: string;
  secondaryButtonFocusedBoxShadow: string;
  dangerButtonColor: string;
  dangerButtonTextColor: string;
  dangerButtonBoxShadow: string;
  dangerButtonHoverColor: string;
  dangerButtonFocusedBoxShadow: string;

  /*---Highlights---*/
  highlightBackgroundColor: string;
  highlightTextColor: string;
  highlightBorderColor: string;
  warningBackgroundColor: string;
  warningTextColor: string;
  warningBorderColor: string;
  dangerBackgroundColor: string;
  dangerTextColor: string;
  dangerBorderColor: string;
  successBackgroundColor: string;
  successTextColor: string;
  successBorderColor: string;

  section: CSSObject & {
    heading: CSSObject;
    _content: CSSObject;
    displayDividerOnStrokes: boolean;
    displayEmptyHeaderOnStrokes: boolean;
    strokes: {
      highlight: SectionStrokeStyle;
      warning: SectionStrokeStyle;
      danger: SectionStrokeStyle;
      success: SectionStrokeStyle;
    };
  };

  explanatoryText: CSSObject & {
    strokes: {
      highlight: CSSObject;
      warning: CSSObject;
      danger: CSSObject;
      success: CSSObject;
    };
  };
}
