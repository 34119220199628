export const urTranslations = {
  ur: {
    metadata: {
      debugDisplayName: 'Urdu',
    },
    translations: {
      add: 'جمع',
      annotatedImage: {
        noAnnotations: 'کوئی رائے ریکارڈ نہیں کیا گیا',
      },
      booleanButtons: {
        no: 'نہیں',
        yes: 'جی ہاں',
      },
      cancel: 'منسوخ کریں',
      clear: 'صاف کریں',
      delete: 'ختم کرنا',
      footer: {
        externalLinks: {
          accessibility: 'رسائی',
          autoTranslate: 'خودکار ترجمہ کی پالیسی',
          privacy: 'رازداری کی پالیسی',
        },
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'یہ فارم استعمال سے خارج کر دیا گیا ہے۔ اس میں موجود معلومات درست نہیں ہیں',
          dialog: {
            content: 'یہ فارم استعمال سے خارج کر دیا گیا ہے۔ اس میں موجود معلومات درست نہیں ہیں',
            title: 'فارم واپس لے لیا گیا',
          },
        },
      },
      freetextTranslations: {
        autotranslateLabel: 'خودکار ترجمہ شدہ سے {{dateTime}} اردو میں ترجمہ کیا گیا ہے',
        hideOriginalText: 'اصل تحریر چھپاو',
        showOriginalText: 'اصل تحریر دکھائیں',
      },
      noOptionsFound: 'کوئی آپشن نہیں ملا',
      otherControl: {
        other: 'دیگر',
      },
      partialDate: {
        full: 'پورا تاریخ',
        monthYear: 'مہینہ اور سال',
        year: 'صرف سال',
      },
      repeat: {
        deleteRow: 'سط  کو ہٹا دیں',
        deleteRowConfirmation: {
          message: 'کیا آپ واقعی اس سط  کو ختم کرنا چاہتے ہیں؟ یہ عمل واپس نہیں لیا جا سکتا.',
          title: 'منظور کریں',
        },
      },
      requestInFlightPopup: {
        heading: 'باقی پیشرفت کی درخواست',
        message:
          'موجودہ درخواست مکمل ہونے تک آگے نہیں بڑھا جا سکتا۔ برائے مہربانی تھوڑی دیر بعد دوبارہ کوشش کریں',
      },
      rotate: 'تصویر کو گھمانے کے لیے بٹن پر کلک کریں',
      splitDate: {
        day: 'دن',
        month: 'مہینہ',
        year: 'سال',
      },
      submissionFailedPopup: {
        heading: 'درخواست ناکام رہی',
        message: 'فارم جمع نہیں ہوا ۔ اپنا انٹرنیٹ کنکشن چیک کریں اور دوبارہ کوشش کریں',
      },
      validationPopup: {
        heading: 'تصدیق کی غلطیاں',
        message: 'فارم میں تصدیق کی غلطیاں ہیں۔ برائے مہربانی کسی بھی غلط فیلڈز کو درست کریں',
      },
      wizard: {
        nextStep: 'اگلا مرحلہ',
        previousStep: 'پچھلا مرحلہ',
        submit: 'جمع کریں',
      },
    },
  },
};
