export const trTranslations = {
  tr: {
    metadata: {
      debugDisplayName: 'Turkish',
    },
    translations: {
      delete: 'Sil',
      cancel: 'İptal',
      add: 'Ekle',
      clear: 'Temizle',
      rotate: 'Çevir',
      wizard: {
        nextStep: 'Sonraki adım',
        previousStep: 'Önceki adım',
        submit: 'Gönder',
      },
      validationPopup: {
        heading: 'Doğrulama Hatası',
        message: 'Doldurduğunuz formda yazım hataları var. Lütfen hatalı alanları düzeltiniz.',
      },
      submissionFailedPopup: {
        heading: 'Gönderim başarısız oldu',
        message: 'Formu gönderirken bir hata oluştu. Lütfen tekrar deneyin.',
      },
      booleanButtons: {
        yes: 'Evet',
        no: 'Hayır',
      },
      otherControl: {
        other: 'Diğer',
      },
      splitDate: {
        day: 'Gün',
        month: 'Ay',
        year: 'Yıl',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'Bu form kullanımdan kaldırıldı. İçerisindeki bilgiler doğru olmayabilir.',
          dialog: {
            title: 'Formu kaldır',
            content: 'Bu form kullanımdan kaldırıldı. İçerisindeki bilgiler doğru olmayabilir.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Ek açıklamalar kayıt edilmedi.',
      },
      repeat: {
        deleteRow: 'Satırı sil',
        deleteRowConfirmation: {
          title: 'Silme işlemini onayla',
          message: 'Bu satırı silmek istediğinize emin misiniz? bu işlem geri alınamaz.',
        },
      },
    },
  },
};
