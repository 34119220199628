import BooleanDefinition from './Boolean';
import DateDefinition from './Date';
import DateTimeDefinition from './DateTime';
import DecimalDefinition from './Decimal';
import EmailDefinition from './Email';
import IntegerDefinition from './Integer';
import StringDefinition from './String';
import TimeDefinition from './Time';

export enum DataType {
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  EMAIL = 'email',
  DATETIME = 'datetime',
}

export interface ITypeDefinition {
  //These aren't called yet, but they probably should be for locales.
  formatToLocale: (value: string) => string;
  parseToXmlValue: (value: string) => string;
  isValid: (value: string) => boolean;
}

export const getTypeDefinition = (type: DataType): ITypeDefinition => {
  switch (type) {
    case DataType.BOOLEAN:
      return new BooleanDefinition();
    case DataType.INTEGER:
      return new IntegerDefinition();
    case DataType.DECIMAL:
      return new DecimalDefinition();
    case DataType.DATE:
      return new DateDefinition();
    case DataType.TIME:
      return new TimeDefinition();
    case DataType.EMAIL:
      return new EmailDefinition();
    case DataType.DATETIME:
      return new DateTimeDefinition();
    default:
      return new StringDefinition();
  }
};
