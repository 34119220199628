import { action, computed, makeObservable } from 'mobx';
import BuildingBlock from './BuildingBlock';

export default class Page {
  constructor(
    public readonly title: string,
    public readonly buildingBlocks: BuildingBlock[],
  ) {
    makeObservable(this, {
      isRelevant: computed,
      validate: action,
      isValid: computed,
    });
  }

  get identifier(): string {
    return this.title.split(' ').join('-');
  }

  get isRelevant(): boolean {
    return this.buildingBlocks.some((block) => block.isRelevant);
  }

  get isHiddenFromUi(): boolean {
    return this.buildingBlocks.every((block) => block.isHiddenFromUi);
  }

  public validate(): boolean {
    for (const buildingBlock of this.buildingBlocks) {
      buildingBlock.validate();
    }
    return this.isValid;
  }

  get isValid(): boolean {
    return this.buildingBlocks.every((block) => block.isValid);
  }

  get updatedSinceLastAutosave(): boolean {
    return this.buildingBlocks.some((block) => block.updatedSinceLastAutosave);
  }

  get hasControlsWithRequestInFlight(): boolean {
    return this.buildingBlocks.some(
      (buildingBlocks) => buildingBlocks.hasControlsWithRequestInFlight,
    );
  }

  public markControlsAsNotUpdated() {
    for (const buildingBlock of this.buildingBlocks) {
      buildingBlock.markControlsAsNotUpdated();
    }
  }
}
