import { action, computed, makeObservable } from 'mobx';
import { ViewMode } from '../Definitions/ViewMode';
import Bind from './Bind';
import Section from './Section';

export default class BuildingBlock {
  constructor(
    public readonly id: string,
    private readonly bind: Bind,
    public readonly sections: Section[],
    public readonly viewMode: ViewMode,
  ) {
    makeObservable(this, {
      isRelevant: computed,
      isValid: computed,
      validate: action,
    });
  }

  get isRelevant(): boolean {
    return this.bind.isRelevant;
  }

  get isHiddenFromUi(): boolean {
    return this.sections.every((section) => section.isHiddenFromUi);
  }

  get isValid(): boolean {
    return this.bind.isValid;
  }

  public validate(): void {
    for (const section of this.sections) {
      section.validate();
    }
  }

  get updatedSinceLastAutosave(): boolean {
    return this.sections.some((section) => section.updatedSinceLastAutosave);
  }

  get touched(): boolean {
    return this.sections.some((section) => section.touched);
  }

  get hasControlsWithRequestInFlight(): boolean {
    return this.sections.some((section) => section.hasControlsWithRequestInFlight);
  }

  public markControlsAsNotUpdated() {
    for (const section of this.sections) {
      section.markControlsAsNotUpdatedSinceLastAutosave();
    }
  }
}
