import axios from 'axios';

type IDictionary = Record<string, string>;

interface CalculationResponse {
  result: string;
}

export interface CalculationRequest {
  key: string;
  version: number;
  valuesOfInterest: IDictionary;
  organisationIdentifier: string;
  dataSource: string;
}

export const calculate = async (
  request: CalculationRequest,
  signal: AbortSignal,
): Promise<string> => {
  const response = await axios.post<CalculationResponse>(
    `/internalapi/serversidecalculation`,
    request,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      signal: signal,
    },
  );

  return response.data.result;
};
