export default class NavigateParams {
  constructor(search: string) {
    const parsedQuery = new URLSearchParams(search);
    this.navigate = parsedQuery.get('navigate');
    this.navigateOnSubmit = parsedQuery.get('navigateOnSubmit');
    this.navigateOnSaveDraft = parsedQuery.get('navigateOnSaveDraft');
    this.navigateOnDiscard = parsedQuery.get('navigateOnDiscard');
    this.navigateOnClose = parsedQuery.get('navigateOnClose');
  }

  private navigate: string | null;
  private navigateOnSubmit: string | null;
  private navigateOnSaveDraft: string | null;
  private navigateOnDiscard: string | null;
  private navigateOnClose: string | null;

  public get onSubmit(): string | null {
    return this.navigateOnSubmit || this.navigate;
  }
  public get onSaveDraft(): string | null {
    return this.navigateOnSaveDraft || this.navigate;
  }
  public get onDiscard(): string | null {
    return this.navigateOnDiscard || this.navigate;
  }
  public get onClose(): string | null {
    return this.navigateOnClose || this.navigate;
  }
}
