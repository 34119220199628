import Enumerable from 'linq';
import { action, makeObservable, observable } from 'mobx';
import { Element } from 'slimdom';
import { IMasterChildDefinition } from '../Definitions/IMasterChildDefinition';
import Bind from './Bind';
import BindStore from './BindStore';
import Control from './Control';
import MasterChildIteration from './MasterChildIteration';
import { Repeatable } from './Repeatable';
import Row from './Row';

export default class MasterChild extends Repeatable<IMasterChildDefinition, MasterChildIteration> {
  constructor(
    definition: IMasterChildDefinition,
    bind: Bind,
    element: Element,
    bindStore: BindStore,
  ) {
    super(definition, bind, element, bindStore);

    makeObservable<MasterChild, 'createIteration'>(this, {
      expandedIteration: observable,
      createIteration: action,
    });
  }

  public expandedIteration: MasterChildIteration | null;

  get id(): string {
    return this.definition.id;
  }

  get noItemsText(): string {
    return this.definition.settings.noItemsText;
  }

  protected createIteration(
    iterationBind: Bind,
    iterationElement: Element,
    rows: Row[],
  ): MasterChildIteration {
    const rowControls = Enumerable.from(rows)
      .selectMany((row) => row.cells)
      .where((cell) => cell.control !== undefined)
      .select((cell) => cell.control);

    const summaryControls = this.definition.summaryRows.map((summmaryRow) => {
      const controls = Enumerable.from(summmaryRow.controlIds)
        .join(
          rowControls,
          (summaryControlId: string) => summaryControlId,
          (control: Control) => control.id,
          (summaryControlId: string, control: Control) => {
            return control;
          },
        )
        .toArray();
      return { controls };
    });

    const iteration = new MasterChildIteration(
      iterationBind,
      iterationElement,
      summaryControls,
      rows,
    );
    this.expandedIteration = iteration;
    return iteration;
  }
}
