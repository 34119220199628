import { darken } from '@mui/material';
import AireLogicTheme from './AireLogic';
import { FormTheme } from './FormTheme';

const explanatoryTextStrokes = {
  highlight: {
    borderWidth: '20px 2px 2px 2px',
  },
  warning: {
    borderWidth: '20px 2px 2px 2px',
  },
  danger: {
    borderWidth: '20px 2px 2px 2px',
  },
  success: {
    borderWidth: '20px 2px 2px 2px',
  },
};

const AireLogicCompactTheme = (primaryColor: string, primaryTextColor: string): FormTheme => {
  const baseTheme = AireLogicTheme(primaryColor, primaryTextColor);

  const highlightBorderColor = darken(baseTheme.highlightBackgroundColor, 0.2);
  const warningBorderColor = darken(baseTheme.warningBackgroundColor, 0.2);
  const dangerBorderColor = darken(baseTheme.dangerBackgroundColor, 0.2);
  const successBorderColor = darken(baseTheme.successBackgroundColor, 0.2);

  baseTheme.name = 'DefaultCompact';
  baseTheme.fontFamily = 'Arial, sans-serif';
  baseTheme.checkboxSize = '20px';
  baseTheme.checkboxLabelPadding = '5px 0';
  baseTheme.checkboxPadding = '4px 4px 6px 0';

  baseTheme.explanatoryText.strokes = explanatoryTextStrokes;

  baseTheme.section = {
    ...baseTheme.section,
    displayDividerOnStrokes: false,
    displayEmptyHeaderOnStrokes: true,
    boxShadow: '0 .125rem .25rem rgba(0,0,0,.15)',
    border: '1px solid #ddd',
    heading: {
      backgroundColor: '#eee',
      padding: '10px 0px 10px 15px',
      margin: 0,
      borderBottom: '1px solid #ddd',
    },
    _content: {
      ...baseTheme.section._content,
      backgroundColor: '#F5F7F8',
    },
    strokes: {
      highlight: {
        section: {
          borderColor: highlightBorderColor,
        },
        heading: {
          backgroundColor: baseTheme.highlightBackgroundColor,
          borderBottomColor: highlightBorderColor,
          color: '#fff',
        },
      },
      warning: {
        section: {
          borderColor: warningBorderColor,
        },
        heading: {
          backgroundColor: baseTheme.warningBackgroundColor,
          borderBottomColor: warningBorderColor,
        },
      },
      danger: {
        section: {
          borderColor: dangerBorderColor,
        },
        heading: {
          backgroundColor: baseTheme.dangerBackgroundColor,
          borderBottomColor: dangerBorderColor,
          color: '#fff',
        },
      },
      success: {
        section: {
          borderColor: successBorderColor,
        },
        heading: {
          backgroundColor: baseTheme.successBackgroundColor,
          borderBottomColor: successBorderColor,
          color: '#fff',
        },
      },
    },
  };

  return baseTheme;
};

export default AireLogicCompactTheme;
