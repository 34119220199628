import SpinnerWithText from './SpinnerWithText';

interface IProps {
  fullscreen?: boolean;
  show?: boolean;
}

const Loading = ({ fullscreen = false, show = true }: IProps) => {
  return show ? <SpinnerWithText label="Loading, please wait." fullscreen={fullscreen} /> : null;
};

export default Loading;
