import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

// https://github.com/softonic/axios-retry/issues/87
const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

// https://github.com/softonic/axios-retry/issues/138
const simpleNetworkErrorCheck = (error: AxiosError) => {
  if (error.message === 'Network Error') {
    return true;
  } else {
    return axiosRetry.isNetworkError(error);
  }
};

const configureAxios = () => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay,
    retryCondition: simpleNetworkErrorCheck,
  });
};

export default configureAxios;
