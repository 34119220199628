import { profiler } from '@airelogic/fontoxpath';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { InstanceStore } from '../../Components/InstanceStore';
import './Debug.css';
import DocDirectionToggle from './DocDirectionToggle';
import FontoProfilingTools from './FontoProfilingTools';
import InstanceRenderComponent from './InstanceRender.Component';
import LanguageSelector from './LanguageSelector';

interface IProps {
  instanceStore: InstanceStore;
}

interface IState {
  isDisplayed: boolean;
  hasBeenClicked: boolean;
}

const appVersion = process.env.NX_PUBLIC_APP_VERSION;

class DebugComponent extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isDisplayed:
        new URLSearchParams(window.location.search).get('xpathPerformance') === 'true'
          ? true
          : false,
      hasBeenClicked: false,
    };
  }

  private handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'F2') {
      event.preventDefault();
      this.setState((previous) => ({
        isDisplayed: !previous.isDisplayed,
        hasBeenClicked: true,
      }));
      if (!this.state.hasBeenClicked) {
        profiler.startProfiling();
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  renderInstances() {
    return this.props.instanceStore.instances.map((instance) => (
      <InstanceRenderComponent key={instance.id} instance={instance} />
    ));
  }

  render() {
    return this.state.isDisplayed ? (
      <div id="debug" dir="ltr">
        <div>Debug Tools</div>
        <div>Build: {appVersion}</div>
        <LanguageSelector />
        <DocDirectionToggle />
        <FontoProfilingTools />
        {this.renderInstances()}
      </div>
    ) : null;
  }
}

export default observer(DebugComponent);
