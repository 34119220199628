import { setDefaultOptions } from 'date-fns';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { logError } from './Services/Logging.Service';
import { translations } from './translations';

const i18nFormInstance = i18n.createInstance();

i18nFormInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    detection: {
      lookupQuerystring: 'lang',
      caches: [],
    },
    // we init with resources, we could also load these from a server depending how large they become.
    resources: {
      ...translations,
    },
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: false,
    load: 'languageOnly',
  });

const updateMomentLocale = (locale: string) => {
  const momentLocale = locale.toLowerCase(); //Moment has its locales lowercased so en-GB is en-gb

  if (momentLocale === 'cimode') {
    moment.locale('en');
    return;
  }

  if (momentLocale === 'en' || momentLocale === 'en-us') {
    moment.locale(momentLocale); //En is already included
    return;
  }

  import(`moment/locale/${momentLocale}`)
    .then(() => {
      moment.locale(momentLocale);
    })
    .catch((x) => logError(x));
};

const updateDateFnsLocale = (locale: string) => {
  if (locale === 'cimode') {
    setDefaultLocale('en-US');
    return;
  }

  import(`date-fns/locale/${locale}/index.js`)
    .then((localeModule) => {
      setDefaultOptions({ locale: localeModule.default });
      registerLocale(locale, localeModule.default);
      setDefaultLocale(locale);
    })
    .catch((x) => logError(x));
};

updateMomentLocale(i18nFormInstance.language);
updateDateFnsLocale(i18nFormInstance.language);

i18nFormInstance.on('languageChanged', async (lang) => {
  updateMomentLocale(lang);
  updateDateFnsLocale(lang);
});

export default i18nFormInstance;
