import Bind from '../Components/Bind';
import Control from '../Components/Control';
import ServerSideCalculation from '../Components/Controls/ServerSideCalculation/ServerSideCalculation';
import { IControlDefinition } from '../Definitions/IControlDefinition';

export const createControl = (
  controlDefinition: IControlDefinition,
  bind: Bind,
  iterationId?: string,
) => {
  switch (controlDefinition.type) {
    case 'server-side-calculation':
      return new ServerSideCalculation(controlDefinition, bind, iterationId);

    default:
      return new Control(controlDefinition, bind, iterationId);
  }
};
