import { lazyRetry } from '@airelogic/common';
import { profiler } from '@airelogic/fontoxpath';
import { when } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import DebugComponent from './Components/DevTools/Debug.Component';
import { convertForm } from './Conversion/FormConverter';
import { IFormDefinition } from './Definitions/IFormDefinition';
import DocumentFormContext from './Layouts/Document/FormContext';
import { SubmissionService } from './Persistence/SubmissionService';
import { loadTheme } from './Themes/ThemeLoader';
import { ThemeProvider } from './Themes/ThemeProvider';

interface IProps {
  formDefinition: IFormDefinition;
  onFormCompleted?: () => void;
  submissionService?: SubmissionService;
}

const WizardForm = lazyRetry(() => import('./Layouts/Wizard/Form'));
const DocumentForm = lazyRetry(() => import('./Layouts/Document/Form'));

const Form = observer((props: IProps) => {
  const { formDefinition, submissionService, onFormCompleted } = props;

  profiler.setPerformanceImplementation(window.performance);
  if (new URLSearchParams(window.location.search).get('xpathPerformance') === 'true') {
    profiler.startProfiling();
  }

  const [theme] = useState(() => loadTheme(formDefinition.layout.themeOptions));
  //Ensure this is lazy by passing a function to setState as this is an expensive operation and we dont want to do it on each render.
  const [{ form, instanceStore, persistenceHandler }] = useState(() =>
    convertForm(formDefinition, submissionService),
  );

  useIdleTimer({
    onAction: persistenceHandler.keepAlive,
    throttle: 5 * 60 * 1000,
  });

  useEffect(() => {
    return when(
      () => persistenceHandler.formCompleted,
      () => {
        if (onFormCompleted) {
          onFormCompleted();
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // Handled by mobx When
  }, []);

  useEffect(() => {
    if (form.title.trim() !== '') document.title = form.title;
    else document.title = 'forms4health';

    return () => {
      document.title = 'forms4health';
    };
  }, [form.title]);

  const layout = () => {
    if (formDefinition.layout.type === 'document') {
      const context = new DocumentFormContext(
        { ...formDefinition, layoutSettings: formDefinition.layout },
        persistenceHandler,
        form,
      );
      return <DocumentForm context={context} />;
    } else {
      return (
        <WizardForm
          form={form}
          persistence={persistenceHandler}
          layoutSettings={formDefinition.layout}
        />
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <DebugComponent instanceStore={instanceStore} />
      {layout()}
    </ThemeProvider>
  );
});

export default Form;
