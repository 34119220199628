export const enTranslations = {
  en: {
    metadata: {
      debugDisplayName: 'English',
    },
    translations: {
      delete: 'Delete',
      cancel: 'Cancel',
      add: 'Add',
      clear: 'Clear',
      rotate: 'Rotate',
      noOptionsFound: 'No options found.',
      wizard: {
        nextStep: 'Next step',
        previousStep: 'Previous step',
        submit: 'Submit',
      },
      validationPopup: {
        heading: 'Validation errors',
        message: 'The form contains validation errors. Please correct any invalid fields.',
      },
      submissionFailedPopup: {
        heading: 'Submission failed',
        message:
          'An error occurred while submitting the form. Check your connection and try again.',
      },
      requestInFlightPopup: {
        heading: 'Outstanding in-flight request',
        message:
          'Unable to continue until current request has completed. Please try again shortly.',
      },
      booleanButtons: {
        yes: 'Yes',
        no: 'No',
      },
      otherControl: {
        other: 'Other',
      },
      splitDate: {
        day: 'Day',
        month: 'Month',
        year: 'Year',
      },
      partialDate: {
        full: 'Full date',
        monthYear: 'Month and year',
        year: 'Year only',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner:
            'This form has been withdrawn from use. The information it contains may not be accurate.',
          dialog: {
            title: 'Form withdrawn',
            content:
              'This form has been withdrawn from use. The information it contains may not be accurate.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'No annotations recorded',
      },
      repeat: {
        deleteRow: 'Delete row',
        deleteRowConfirmation: {
          title: 'Confirm deletion',
          message: 'Are you sure you want to delete the row? This action cannot be undone.',
        },
      },
      freetextTranslations: {
        autotranslateLabel: 'Auto translated text on {{dateTime}}',
        showOriginalText: 'Show original text',
        hideOriginalText: 'Hide original text',
      },
      footer: {
        externalLinks: {
          accessibility: 'Accessibility',
          privacy: 'Privacy Policy',
          autoTranslate: 'Auto Translate Policy',
        },
      },
      fileUpload: {
        uploading: 'Uploading...',
        uploadComplete: 'File succesfully uploaded',
        maxFileSize: 'Max file size: 50MB',
        fileTooLarge: 'File is too large',
        failedToUpload: 'File failed to upload',
        unsupportedFileType: 'File type not supported',
        retrievingFileLink: 'Retrieving file link...',
        fileNotFound: 'Unable to retrieve file',
      },
    },
  },
};
