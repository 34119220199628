import { sanitize } from '@airelogic/sanitize-xml-string';
import {
  IXPathEvaluator,
  readOnlyAttributeName,
  relevanceAttributeName,
  validAttributeName,
} from '@airelogic/xpath';
import differenceBy from 'lodash/differenceBy';
import {
  IReactionDisposer,
  ObservableMap,
  action,
  computed,
  entries,
  makeObservable,
  observable,
  reaction,
} from 'mobx';
import { Document, Element, XMLSerializer } from 'slimdom';
import { IBindDefinition } from '../Definitions/IBindDefinition';
import {
  DataType,
  ITypeDefinition,
  getTypeDefinition,
} from '../XForms/Types/TypeDefinitionFactory';
import { ValueOfInterest } from './ValueOfInterest';

const textContentNodeType = 3;

interface IBindStore {
  getBindById(id: string, repeatIterationId?: string): Bind;
  getBindsById(id: string): Bind[];
  getChildBinds(parentId: string, repeatIterationId?: string): Bind[];
}

export const displayNameAttribute = 'displayName';

export default class Bind {
  public validationError = '';

  public readonly isCalculated: boolean;
  private readonly slimDomDocument = new Document();
  private readonly typeDefinition: ITypeDefinition;

  constructor(
    private readonly xPathEvaluator: IXPathEvaluator,
    private readonly bindStore: IBindStore,
    private readonly definition: IBindDefinition,
    public readonly referencedBindIds: string[],
  ) {
    makeObservable<
      Bind,
      | 'bindsOfInterest'
      | '_value'
      | 'required'
      | 'readOnly'
      | '_filterGroups'
      | '_valuesOfInterest'
      | 'relevant'
      | 'valid'
      | 'evaluateState'
      | 'evaluateIsRequired'
      | 'evaluateIsRelevant'
      | 'evaluateIsReadOnly'
      | 'evaulateFilterGroups'
      | 'evaluateValuesOfInterest'
      | '_additionalAttributes'
      | 'badInput'
      | 'clearBadInputFlag'
    >(this, {
      validationError: observable,
      attachToElement: action,
      bindsOfInterest: computed,
      _value: observable,
      value: computed,
      displayName: computed,
      _additionalAttributes: observable,
      additionalAttributes: computed.struct,
      updateValue: action,
      clearValue: action,
      updateValueAsXml: action,
      required: observable,
      isRequired: computed,
      readOnly: observable,
      isReadOnly: computed,
      _filterGroups: observable,
      filterGroups: computed.struct,
      _valuesOfInterest: observable,
      valuesOfInterest: computed.struct,
      relevant: observable,
      isRelevant: computed,
      valid: observable,
      isValid: computed,
      validate: action,
      evaluateState: action,
      evaluateIsRequired: action,
      evaluateIsRelevant: action,
      evaluateIsReadOnly: action,
      evaulateFilterGroups: action,
      evaluateValuesOfInterest: action,
      clone: action,
      markAsBadInput: action,
      badInput: observable,
      clearBadInputFlag: action,
    });

    this.isCalculated = definition.calculatedValueXPath ? true : false;
    this.typeDefinition = getTypeDefinition(definition.dataType.type);
    this.badInput = false;
  }

  public get id() {
    return this.definition.id;
  }

  public get ref() {
    return this.definition.ref;
  }

  public get fullRef() {
    return this.definition.fullRef;
  }

  public get repeatIterationId() {
    return this.definition.repeatIterationId;
  }

  public get parentBindId() {
    return this.definition.parentId;
  }

  private _additionalAttributes = new ObservableMap<string, string>();

  public get additionalAttributes(): Map<string, string> {
    return this._additionalAttributes;
  }

  private element: Element;

  private readonly toDispose = new Array<IReactionDisposer>();

  public attachToElement(element: Element): void {
    this.ensureElementHasChildTextNode(element);
    this.element = element;
    this.disposeReactions();

    for (const attribute of element.attributes) {
      this._additionalAttributes.set(attribute.name, attribute.value);
    }

    this._updateValue(this.calculateInitialValue());

    if (this.isCalculated) {
      this._updateValue(this.calculateValue());
    }

    const valueOnlyReaction = reaction(
      () => this.bindsOfInterest.map((bind) => bind.value),
      () => {
        this.evaulateFilterGroups();
        this.evaluateValuesOfInterest();
      },
      {
        fireImmediately: true,
      },
    );

    const valueAndAdditionalAttributesReaction = reaction(
      () => [this.value, entries(this._additionalAttributes)],
      (
        current: [string, readonly [string, string][]],
        previous: [string, readonly [string, string][]] | undefined,
      ) => {
        this.updateAdditionalAttributes(
          current[1] as readonly [string, string][],
          previous && previous[1],
        );
        this.evaluateState();
      },
      {
        name: 'additional attributes reaction',
        fireImmediately: true,
      },
    );

    const referencedBindReaction = reaction(
      () =>
        this.bindsOfInterest.map((bind) => [
          bind.value,
          bind.isValid,
          bind.isReadOnly,
          bind.isRelevant,
          entries(bind.additionalAttributes),
        ]),
      (updatedBinds) => {
        if (updatedBinds.length > 0) {
          if (this.isCalculated) {
            this._updateValue(this.calculateValue());
          }
          this.evaluateState();
        }
      },
      {
        name: 'Updating ' + this.definition.id,
        fireImmediately: true,
      },
    );

    this.toDispose.push(
      ...[valueOnlyReaction, referencedBindReaction, valueAndAdditionalAttributesReaction],
    );
  }

  public disposeReactions() {
    for (const disposable of this.toDispose) {
      disposable();
    }

    this.toDispose.splice(0, this.toDispose.length);
  }

  private updateAdditionalAttributes(
    current: readonly [string, string][],
    previous: readonly [string, string][] | undefined,
  ) {
    if (previous) {
      const removedAttributes = differenceBy(previous, current, (c) => c[0]).map((c) => c[0]);
      for (const attribute of removedAttributes) {
        this.element.removeAttribute(attribute);
      }
    }

    for (const additionalAttribute of current) {
      const cleanedValue = sanitize(additionalAttribute[1]);
      this.element.setAttribute(additionalAttribute[0], cleanedValue);
    }
  }

  private get bindsOfInterest(): Bind[] {
    if (this.parentBind) {
      return [this.parentBind, ...this.referencedBinds, ...this.childBinds];
    }

    return [...this.referencedBinds, ...this.childBinds];
  }

  private calculateInitialValue(): string {
    if (this.element.firstChild!.nodeType === textContentNodeType) {
      return this.element.firstChild!.nodeValue
        ? this.element.firstChild!.nodeValue!
        : this.xPathEvaluator.evaluateXPathToStringWithFallbacks(
            this.definition.initialValueXPath,
            this.element,
            { errorValue: '', defaultValue: '' },
          );
    }

    return new XMLSerializer().serializeToString(this.element.firstChild!);
  }

  private ensureElementHasChildTextNode(element: Element) {
    if (element.firstChild == null) {
      element.appendChild(this.slimDomDocument.createTextNode(''));
    }
  }

  private get referencedBinds(): Bind[] {
    if (this.referencedBindIds.length > 0) {
      const binds = [];
      for (const bindId of this.referencedBindIds) {
        binds.push(...this.bindStore.getBindsById(bindId));
      }
      return binds;
    }

    return [];
  }

  public get parentBind(): Bind | undefined {
    if (this.definition.parentId) {
      return this.bindStore.getBindById(this.definition.parentId, this.repeatIterationId);
    }
    return undefined;
  }

  public get childBinds(): Bind[] {
    return this.bindStore.getChildBinds(this.definition.id, this.repeatIterationId);
  }

  private badInput: boolean;

  private _value: string;

  public get value(): string {
    return this._value;
  }

  public get displayName(): string {
    return this.additionalAttributes.get(displayNameAttribute) ?? '';
  }

  public updateValue(value: string): void {
    //Apply the current value before we re-apply the calculation.
    this._updateValue(value);

    if (this.isCalculated) {
      this._updateValue(this.calculateValue());
    }
  }

  public get valueAsXML(): Element | null {
    return this.element;
  }

  public clearValue(): void {
    this.clearChildElements();
    this.ensureElementHasChildTextNode(this.element);
    this.updateValue('');
  }

  public markAsBadInput() {
    this.badInput = true;
  }

  public clearBadInputFlag() {
    this.badInput = false;
  }

  public updateValueAsXml(value: Element, includeRootNode: boolean) {
    this.clearChildElements();

    if (value) {
      if (includeRootNode === false) {
        value.children.forEach((el) => {
          this.element.appendChild(el.cloneNode(true));
        });
      } else {
        this.element.appendChild(value.cloneNode(true));
      }

      this._value = new XMLSerializer().serializeToString(value);
    } else {
      this._value = '';
    }
  }

  private clearChildElements() {
    while (this.element.hasChildNodes()) {
      this.element.removeChild(this.element.lastChild!);
    }
  }

  private _updateValue(value: string) {
    //Order is important here.
    //We need to make sure the data is updated before any actions are triggered from Updating the value.
    const cleanedValue = this.typeDefinition.parseToXmlValue(value);
    this.element.firstChild!.nodeValue = cleanedValue;
    this._value = cleanedValue;
  }

  private calculateValue(): string {
    return this.xPathEvaluator.evaluateXPathToStringWithFallbacks(
      this.definition.calculatedValueXPath,
      this.element,
      { errorValue: '', defaultValue: '' },
    );
  }

  get dataType(): DataType {
    return this.definition.dataType.type;
  }

  private required = false;

  get isRequired(): boolean {
    return this.required;
  }

  get couldBeRequired(): boolean {
    return this.definition.required.xpath !== 'false()';
  }

  private readOnly = false;

  get isReadOnly(): boolean {
    return this.readOnly;
  }

  private _filterGroups: string[] | null = null;

  get filterGroups(): string[] | null {
    return this._filterGroups;
  }

  private _valuesOfInterest: ValueOfInterest[] = [];

  get valuesOfInterest(): ValueOfInterest[] {
    return this._valuesOfInterest;
  }

  private relevant = true;

  get isRelevant(): boolean {
    return this.relevant;
  }

  private valid = false;

  get isValid(): boolean {
    return this.valid;
  }

  public validate() {
    this.validationError = '';
    this.valid =
      this.isRelevant === false ||
      (this.satisfiesRequiredConstraint() &&
        this.satisfiesTypeConstraint() &&
        this.satisfiesConstraints() &&
        this.childBinds.every((bind) => bind.isValid));
  }

  private satisfiesTypeConstraint(): boolean {
    const result = this.typeDefinition.isValid(this.value) && !this.badInput;

    if (result === false) {
      this.validationError = this.definition.dataType.errorMessage;
    }

    return result;
  }

  private satisfiesRequiredConstraint(): boolean {
    if (this.isRequired && !this.value.replace(/\s/g, '').length === true) {
      //must not be empty or only contain whitespace
      this.validationError = this.definition.required.errorMessage;
      return false;
    }

    return true;
  }

  private satisfiesConstraints(): boolean {
    //Don't run constraints against non required controls with no value
    if (this.required === false && this.value === '') {
      return true;
    }

    if (this.definition.constraints && this.definition.constraints.length !== 0) {
      for (const constraint of this.definition.constraints) {
        const isValid = this.xPathEvaluator.evaluateXPathToBooleanWithFallbacks(
          constraint.xpath,
          this.element,
          { errorValue: false, defaultValue: true },
        );

        if (isValid === false) {
          this.validationError = constraint.errorMessage;
          return false;
        }
      }

      return true;
    }
    return true;
  }

  private evaluateState(): void {
    this.evaluateIsRequired();
    this.evaluateIsRelevant();
    this.evaluateIsReadOnly();

    this.validate();

    this.element.setAttribute(relevanceAttributeName, Boolean(this.relevant).toString());
    this.element.setAttribute(readOnlyAttributeName, Boolean(this.readOnly).toString());
    this.element.setAttribute(validAttributeName, Boolean(this.isValid).toString());
  }

  private evaluateIsRequired(): void {
    this.required = this.xPathEvaluator.evaluateXPathToBooleanWithFallbacks(
      this.definition.required.xpath,
      this.element,
      { errorValue: false, defaultValue: false },
    );
  }

  private preNotRelevantValue: string | undefined = undefined;

  private preNotRelevantAdditionalAttributes = new ObservableMap();

  private evaluateIsRelevant(): void {
    let isRelevant: boolean;

    if (this.parentBind && this.parentBind.isRelevant === false) {
      isRelevant = false;
    } else {
      isRelevant = this.xPathEvaluator.evaluateXPathToBooleanWithFallbacks(
        this.definition.relevanceXPath,
        this.element,
        { errorValue: false, defaultValue: true },
      );
    }

    if (this.isCalculated === false && isRelevant !== this.relevant && this.element.parentElement) {
      if (isRelevant) {
        if (this.preNotRelevantValue !== undefined) {
          this._updateValue(this.preNotRelevantValue);
        }

        this._additionalAttributes.merge(this.preNotRelevantAdditionalAttributes);
      } else {
        this.preNotRelevantValue = this.value === '' ? undefined : this.value;
        this._updateValue('');
        this.preNotRelevantAdditionalAttributes.clear();
        this.preNotRelevantAdditionalAttributes.merge(this._additionalAttributes);
        for (const key of this.additionalAttributes.keys()) {
          this.additionalAttributes.set(key, '');
        }
      }
    }

    if (this.isCalculated && isRelevant === false) {
      this._updateValue('');
    }

    this.relevant = isRelevant;
  }

  private evaluateIsReadOnly(): void {
    this.readOnly =
      (this.parentBind && this.parentBind.isReadOnly) ||
      (this.isCalculated && this.definition.readOnlyXPath === undefined) || //Probably want to check if we reference ourself, if not it probably should not be readonly
      this.xPathEvaluator.evaluateXPathToBooleanWithFallbacks(
        this.definition.readOnlyXPath,
        this.element,
        { errorValue: false, defaultValue: false },
      );
  }

  private evaulateFilterGroups(): void {
    if (this.definition.itemsetFilterXPath) {
      this._filterGroups = this.xPathEvaluator
        .evaluateXPathToStrings(this.definition.itemsetFilterXPath, this.element)
        .filter((filterGroup) => filterGroup !== '');
    }
  }

  private evaluateValuesOfInterest(): void {
    const values = new Array<ValueOfInterest>();
    if (this.definition.valuesOfInterest) {
      for (const valueOfInterest of this.definition.valuesOfInterest) {
        const result = this.xPathEvaluator.evaluateXPathToStringWithFallbacks(
          valueOfInterest.xPath,
          this.element,
          { errorValue: '', defaultValue: '' },
        );

        values.push({ key: valueOfInterest.key, value: result });
      }
      this._valuesOfInterest = values;
    }
  }

  public clone(repeatIterationId: string, newParentId?: string): Bind {
    if (repeatIterationId === '') {
      throw new Error('repeatIterationId cannot be an empty string');
    }

    if (newParentId === '') {
      throw new Error('newParentId cannot be an empty string');
    }

    const clonedDefinition = JSON.parse(JSON.stringify(this.definition)) as IBindDefinition;
    clonedDefinition.repeatIterationId = repeatIterationId;
    if (newParentId) {
      clonedDefinition.parentId = newParentId;
    }
    return new Bind(this.xPathEvaluator, this.bindStore, clonedDefinition, this.referencedBindIds);
  }
}
