import { FormTheme } from './FormTheme';

const GDSTheme = (primaryColor = '#1d70b8', primaryTextColor = '#fff'): FormTheme => ({
  name: 'GDS',
  primaryColor: primaryColor,
  primaryTextColor: primaryTextColor,
  errorColor: '#bf1b04',
  fontFamily: 'Hind, sans-serif',
  headerFlexDirection: 'column-reverse',
  headerMargin: '0',
  headerBackgroundColor: '#0b0c0c',
  headerTitleTextColor: '#fff',
  headerTitleBorder: 'none',
  headerTitleBorderWidth: '0',
  islandTextColor: '#000',
  islandTextSecondaryColor: '#666666',
  islandBackgroundColor: '#f3f2f1',
  islandBorderTop: '2px solid #b1b4b6',
  islandHeaderFontWeight: 'bold',
  stepSpacingMargin: '0',

  explanatoryTextPadding: '1em',
  explanatoryTextMargin: 0,
  explanatoryTextBorder: 'solid #b1b4b6',
  explanatoryTextBorderWidth: '0px 0px 0px 10px',
  highlightBackgroundColor: 'inherit',
  highlightBorderColor: '#0090c8',
  highlightTextColor: '#000',
  warningBackgroundColor: 'inherit',
  warningTextColor: '#000',
  warningBorderColor: '#ffb64e',
  dangerBackgroundColor: 'inherit',
  dangerTextColor: '#000',
  dangerBorderColor: '#ea6456',
  successBackgroundColor: 'inherit',
  successTextColor: '#000',
  successBorderColor: '#28a745',
  badgeBorderRadius: '0.25rem',
  badgeSuccessBackgroundColor: '#E0E0E0',
  badgeSuccessTextColor: '#333',
  badgeDangerBackgroundColor: '#dc3545',
  badgeDangerTextColor: '#fff',
  formGroupMargin: 0,
  formGroupPadding: '0 0 1em 0',
  formGroupInvalidBorder: 'solid #df3034',
  formGroupInvalidBorderWidth: '0 0 0 5px',
  formGroupInvalidLeft: '-20px',
  formGroupInvalidPadding: '0 0 1em 15px',
  formControlBorderRadius: 0,
  formControlBorderWidth: '2px',
  formControlBorderColor: '#0b0c0c',
  formControlFocusedOutline: '3px solid #fd0',
  formControlFocusedOutlineOffset: 0,
  formControlFocusedBorderColor: 'inherit',
  formControlFocusedBoxShadow: 'inset 0 0 0 2px #0b0c0c',
  formControlInvalidBorder: 'solid #df3034',
  formControlInvalidBorderWidth: '4px',
  selectOptionDisabledBackgroundColor: '#ced4da',
  selectOptionDisabledTextColor: '#000',
  selectOptionSelectedBackgroundColor: '#fff',
  selectOptionSelectedTextColor: '#000',
  selectOptionFocusedBackgroundColor: '#4d90fe',
  selectOptionFocusedTextColor: '#fff',
  selectOptionUnfocusedBackgroundColor: '#fff',
  selectOptionUnfocusedTextColor: '#000',
  radioButtonTwoItemGroupFlexDirection: 'row',
  checkboxSize: '40px',
  checkboxLabelPadding: '19px 0',
  checkboxPadding: '12px 12px 12px 0',
  checkboxFillColor: '#000',
  checkboxCheckedFillColor: '#000',
  checkboxSelectedLabelBackgroundColor: 'inherit',
  checkboxFocusBoxShadow: '0 0 0 0.2rem #0b0c0c',
  buttonBorderRadius: 0,
  defaultButtonColor: '#fff',
  defaultButtonTextColor: '#000',
  defaultButtonBorder: '2px solid #0b0c0c',
  defaultButtonBoxShadow: 'none',
  defaultButtonHoverColor: '#f2f2f2',
  defaultButtonFocusedBoxShadow: '0 0 0 0.2rem #0b0c0c',
  defaultButtonDisabledColor: '#fff',
  defaultButtonDisabledBorder: 'solid 1px #ccc',
  selectedButtonFocusedBoxShadow: '0 0 0 0.2rem #0b0c0c',
  primaryButtonColor: '#00703c',
  primaryButtonTextColor: '#fff',
  primaryButtonBoxShadow: '0 2px 0 #002d18',
  primaryButtonHoverColor: '#005a30',
  primaryButtonFocusedBoxShadow: '0 2px 0 #002d18',
  primaryButtonFocusedColor: '#fd0',
  primaryButtonFocusedTextColor: '#000',
  primaryButtonFocusedBorder: '2px solid #fd0',
  secondaryButtonColor: '#f3f2f1',
  secondaryButtonTextColor: '#0b0c0c',
  secondaryButtonBoxShadow: '0 2px 0 #929191',
  secondaryButtonHoverColor: '#dbdad9',
  secondaryButtonFocusedBoxShadow: `0 2px 0 #929191`,
  dangerButtonColor: '#d4351c',
  dangerButtonTextColor: '#fff',
  dangerButtonBoxShadow: '0 2px 0 #55150b',
  dangerButtonHoverColor: '#aa2a16',
  dangerButtonFocusedBoxShadow: `0 2px 0 #55150b`,

  /*--Section--*/
  section: {
    margin: '0.5em 0 1em',
    heading: {
      padding: '0 0 0.5rem',
      margin: '0.5em 0',
      color: '#000',
    },
    _content: {
      backgroundColor: '#fff',
      color: '#000',
      padding: 0,
      borderWidth: '0px 0px 0px 10px',
    },
    displayDividerOnStrokes: false,
    displayEmptyHeaderOnStrokes: false,
    strokes: {
      highlight: {
        _content: {
          border: 'solid #0090c8',
          paddingLeft: '10px',
        },
      },
      warning: {
        _content: {
          border: 'solid #ffb64e',
          paddingLeft: '10px',
        },
      },
      danger: {
        _content: {
          border: 'solid #ea6456',
          paddingLeft: '10px',
        },
      },
      success: {
        _content: {
          border: 'solid #28a745',
          paddingLeft: '10px',
        },
      },
    },
  },

  explanatoryText: {
    strokes: {
      highlight: {
        borderWidth: '0px 0px 0px 10px',
      },
      warning: {
        borderWidth: '0px 0px 0px 10px',
      },
      danger: {
        borderWidth: '0px 0px 0px 10px',
      },
      success: {
        borderWidth: '0px 0px 0px 10px',
      },
    },
  },
});

export default GDSTheme;
