import DOMPurify from 'dompurify';
import { createElement, ReactHTML } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  id?: string;
  className?: string;
  html?: string;
  element?: keyof ReactHTML;
}

const useHtmlContentStyles = makeStyles()({
  baseStyle: {
    '& h1': {
      fontSize: '1.75rem',
      marginTop: '0.25rem',
      marginBottom: '0.25rem',
    },
    '& h2': {
      fontSize: '1.2rem',
      marginTop: '1rem',
      marginBottom: '0.25rem',
    },
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      width: 'auto',
      backgroundColor: 'white',
      '& th,td': {
        padding: '0.5rem',
        border: '1px solid #889aa5',
        textAlign: 'left',
      },
      '& th': {
        backgroundColor: '#edf0f2',
      },
    },
  },
});

export const sanitizeHtmlString = (html: string) =>
  DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });

const SanitizedHTML = ({ html = '', element = 'div', className, ...rest }: IProps) => {
  const sanitizedHTML = html ? sanitizeHtmlString(html) : '';

  const {
    classes: { baseStyle },
    cx,
  } = useHtmlContentStyles();

  return createElement(element, {
    ...rest,
    className: cx(baseStyle, className),
    dangerouslySetInnerHTML: { __html: sanitizedHTML },
  });
};

export default SanitizedHTML;
