const greaterThanSmall = '(min-width: 576px)';
const greaterThanMedium = '(min-width: 768px)';
const greaterThanLarge = '(min-width: 992px)';
const greaterThanExtraLarge = '(min-width: 1200px)';

const lessThanSmall = '(max-width: 575.98px)';
const lessThanMedium = '(max-width: 767.98px)';
const lessThanLarge = '(max-width: 991.98px)';
const lessThanExtraLarge = '(max-width: 1199.98px)';

export const mediaBreakPoints = {
  greaterThanSmall: greaterThanSmall,
  greaterThanMedium: greaterThanMedium,
  greaterThanLarge: greaterThanLarge,
  greaterThanExtraLarge: greaterThanExtraLarge,
  lessThanSmall: lessThanSmall,
  lessThanMedium: lessThanMedium,
  lessThanLarge: lessThanLarge,
  lessThanExtraLarge: lessThanExtraLarge,
};
