import { Instance } from '@airelogic/xpath';
import createInstanceStore from '../Components/InstanceStore';
import parseXml from '../XmlParser';

interface IFormDefinition {
  buildingBlocks: { key: string; instance: string }[];
  formContext: string | null;
}

export const convertInstances = (formDefinition: IFormDefinition) => {
  const instances = formDefinition.buildingBlocks.map(
    (block) => new Instance(block.key, parseXml(block.instance)),
  );

  if (formDefinition.formContext) {
    instances.push(new Instance('form-context', parseXml(formDefinition.formContext)));
  }

  return createInstanceStore(instances);
};
