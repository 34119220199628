import { validate } from 'email-validator';
import { ITypeDefinition } from './TypeDefinitionFactory';

export default class EmailDefinition implements ITypeDefinition {
  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return value === '' || validate(value);
  }
}
