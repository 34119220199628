export const skTranslations = {
  //Slovakian
  sk: {
    metadata: {
      debugDisplayName: 'Slovakian',
    },
    translations: {
      delete: 'Vymažte',
      cancel: 'Zrušte',
      add: 'Pridajte',
      clear: 'Vymažte',
      rotate: 'Otočte',
      noOptionsFound: 'Nenašli sa žiadne možnosti',
      wizard: {
        nextStep: 'Ďalší krok',
        previousStep: 'Predošlí krok',
        submit: 'Poslať',
      },
      validationPopup: {
        heading: 'Chyby overenia',
        message: 'Formulár obsahuje chyby overenia.',
      },
      submissionFailedPopup: {
        heading: 'Podanie zlyhalo',
        message:
          'Počas podávania formuláru sa vyskytla chyba. Skontrolujte svoje pripojenie a skúste znova.',
      },
      booleanButtons: {
        yes: 'Áno',
        no: 'Nie',
      },
      otherControl: {
        other: 'Iné',
      },
      splitDate: {
        day: 'Deň',
        month: 'Mesiac',
        year: 'Rok',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner: 'Tento formulár sa už nepoužíva. Informácie, ktoré obsahuje môžu byť nepresné.',
          dialog: {
            title: 'Zrušený formulár',
            content: 'Tento formulár sa už nepoužíva. Je možné, že obsahuje nepresné informácie.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Nie sú zaznamenané žiadne anotácie',
      },
      repeat: {
        deleteRow: 'Vymažte riadok',
        deleteRowConfirmation: {
          title: 'Potvrďte vymazanie',
          message: 'Ste si istý, že chcete vymazať riadok? Tento pokyn nie je možné odvolať.',
        },
      },
    },
  },
};
