import { ICellDefinition } from '../Definitions/ICellDefinition';
import Control from './Control';

export default class Cell {
  constructor(
    private readonly definition: ICellDefinition,
    public readonly control?: Control,
  ) {}

  public get cellSpan(): number {
    return this.definition.cellSpan;
  }
}
