import { action, computed, makeObservable, observable, reaction, runInAction, when } from 'mobx';
import { IControlDefinition } from '../../../Definitions/IControlDefinition';
import Bind from '../../Bind';
import Control from '../../Control';

import { CanceledError } from 'axios';
import { logError } from '../../../Services/Logging.Service';
import { calculate, CalculationRequest } from './Service';

export interface ControlDetail {
  key: string;
  version: number;
  serversideCalculation: {
    organisationIdentifier: string;
    dataSource: string;
    fireImmediately: boolean;
  };
}

export default class ServerSideCalculation extends Control<ControlDetail> {
  constructor(controlDefinition: IControlDefinition, bind: Bind, iterationId?: string) {
    super(controlDefinition, bind, iterationId);
    makeObservable<ServerSideCalculation, 'computeValues' | '_isInError'>(this, {
      _isInError: observable,
      isInError: computed,
      computeValues: action,
    });

    reaction(() => this.valuesOfInterest, this.computeValues, {
      delay: 500,
    });

    reaction(() => this.isRelevant, this.computeValues);

    when(() => this.detail.serversideCalculation.fireImmediately === true, this.computeValues);
  }

  private _isInError = false;

  private _abortControler: AbortController = new AbortController();

  get isInError(): boolean {
    return this._isInError;
  }

  private computeValues = async () => {
    this._isInError = false;

    if (this.isRelevant && this.valuesOfInterest.length > 0) {
      let computedValue;

      this._abortControler.abort();
      this._abortControler = new AbortController();

      this.hasRequestInFlight = true;

      try {
        computedValue = await calculate(this.createRequest(), this._abortControler.signal);
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        if (error instanceof Error) {
          logError(error);
        }
        runInAction(() => {
          this._isInError = true;
        });
        this.clearValue();
        this.hasRequestInFlight = false;
        return;
      }

      this.hasRequestInFlight = false;
      this.updateValue(computedValue);
      this.markAsTouched();
    }
  };

  private createRequest = (): CalculationRequest => {
    const valuesOfInterest: Record<string, string> = {};
    for (const iterator of this.valuesOfInterest) {
      valuesOfInterest[iterator.key] = iterator.value;
    }

    return {
      organisationIdentifier: this.detail.serversideCalculation.organisationIdentifier,
      key: this.detail.key,
      version: this.detail.version,
      valuesOfInterest: valuesOfInterest,
      dataSource: this.detail.serversideCalculation.dataSource,
    };
  };
}
