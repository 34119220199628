import axios from 'axios';
import { logError } from '../Services/Logging.Service';
import SubmissionRequest from './SubmissionRequest';
import SubmissionResponse from './SubmissionResponse';

export type SubmissionService = (
  submissionUri: string,
  submissionRequest: SubmissionRequest,
) => Promise<SubmissionResponse>;

export const defaultSubmissionService = async (
  submissionUri: string,
  submissionRequest: SubmissionRequest,
): Promise<SubmissionResponse> => {
  let response;

  try {
    response = await axios(submissionUri, {
      method: 'POST',

      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify(submissionRequest),
    });
  } catch (error) {
    logError(new Error(`Error posting data to ${submissionUri}: ${error}`));
    return Promise.reject();
  }

  if (response.status === 200) {
    return Promise.resolve(response.data as SubmissionResponse);
  }

  logError(
    new Error(`Error posting data to ${submissionUri}: ${response.status} ${response.statusText}`),
  );
  return Promise.reject();
};
