import CryptoJS from 'crypto-js';

export const hashLiteral = (data: string, algo: string, encoding?: string) => {
  let hash;

  switch (algo) {
    case 'SHA-1':
      hash = CryptoJS.SHA1(data);
      break;
    case 'SHA-256':
      hash = CryptoJS.SHA256(data);
      break;
    case 'SHA-384':
      hash = CryptoJS.SHA384(data);
      break;
    case 'SHA-512':
      hash = CryptoJS.SHA512(data);
      break;
    case 'MD5':
      hash = CryptoJS.MD5(data);
      break;
    default:
      return false;
  }

  let encrypted;

  if (encoding === 'hex') {
    encrypted = hash.toString(CryptoJS.enc.Hex);
  } else {
    encrypted = hash.toString(CryptoJS.enc.Base64);
  }

  return encrypted;
};

export const keyedHashLiteral = (secret: string, data: string, algo: string, encoding?: string) => {
  let hash;

  switch (algo) {
    case 'SHA-1':
      hash = CryptoJS.HmacSHA1(data, secret);
      break;
    case 'SHA-256':
      hash = CryptoJS.HmacSHA256(data, secret);
      break;
    case 'SHA-384':
      hash = CryptoJS.HmacSHA384(data, secret);
      break;
    case 'SHA-512':
      hash = CryptoJS.HmacSHA512(data, secret);
      break;
    case 'MD5':
      hash = CryptoJS.HmacMD5(data, secret);
      break;
    default:
      return false;
  }

  let encrypted;

  if (encoding === 'hex') {
    encrypted = hash.toString(CryptoJS.enc.Hex);
  } else {
    encrypted = hash.toString(CryptoJS.enc.Base64);
  }

  return encrypted;
};
