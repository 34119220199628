export default class QueryConfigParams {
  private readonly parsedParams;

  constructor(search: string) {
    this.parsedParams = new URLSearchParams(search);
  }

  public get hideBanner(): boolean {
    return this.parsedParams.get('hideBanner') === 'true';
  }

  public get hideFooter(): boolean {
    return this.parsedParams.get('hideFooter') === 'true';
  }

  public get hideNavigation(): boolean {
    return this.parsedParams.get('hideNavigation') === 'true';
  }
}
