import { XMLSerializer } from 'slimdom';
import { InstanceStore } from '../Components/InstanceStore';
import i18n from '../i18n';
import SubmissionRequest from './SubmissionRequest';

export const composeSubmission = (
  buildingBlocks: { key: string; version: number }[],
  instanceStore: InstanceStore,
  recaptchaToken?: string,
): SubmissionRequest => {
  const buildingBlockSubmissions = buildingBlocks.map((block) => ({
    key: block.key,
    version: block.version,
    data: new XMLSerializer().serializeToString(
      instanceStore.resolve(block.key).cleanForSubmission(),
    ),
  }));

  return {
    languageCode: i18n.language,
    buildingBlockSubmissions: buildingBlockSubmissions,
    recaptchaToken: recaptchaToken,
  };
};
