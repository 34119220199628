export const roTranslations = {
  //Romanian
  ro: {
    metadata: {
      debugDisplayName: 'Romanian',
    },
    translations: {
      delete: 'Ștergeți',
      cancel: 'Anulați',
      add: 'Adăugați',
      clear: 'Goliți',
      rotate: 'Rotiți',
      noOptionsFound: 'Nu s-au găsit opțiuni',
      wizard: {
        nextStep: 'Pasul următor',
        previousStep: 'Pasul anterior',
        submit: 'Trimiteți',
      },
      validationPopup: {
        heading: 'Erori de validare',
        message:
          'Formularul conține erori de validare. Vă rugăm să corectați orice câmpuri nevalide.',
      },
      submissionFailedPopup: {
        heading: 'Trimitere eșuată',
        message:
          'A apărut o eroare la trimiterea formularului. Verificați conexiunea și încercați din nou.',
      },
      booleanButtons: {
        yes: 'Da',
        no: 'Nu',
      },
      otherControl: {
        other: 'Altele',
      },
      splitDate: {
        day: 'Ziua',
        month: 'Luna',
        year: 'Anul',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner:
            'Acest formular a fost retras din uz. Este posibil ca informațiile pe care le conține să nu fie exacte.',
          dialog: {
            title: 'Formular retras',
            content:
              'Acest formular a fost retras din uz. Este posibil ca informațiile pe care le conține să nu fie exacte.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Nu s-au înregistrat adnotări',
      },
      repeat: {
        deleteRow: 'Ștergeți rândul',
        deleteRowConfirmation: {
          title: 'Confirmați ștergerea',
          message: 'Sigur doriți să ștergeți rândul? Această acțiune nu se poate anula.',
        },
      },
    },
  },
};
