import { useState } from 'react';

interface Props {
  value: string;
}

const LargeDisplayValueHandler = ({ value }: Props) => {
  const copyImageLabel = `Click to copy value`;
  const [btnText, setBtnText] = useState(copyImageLabel);

  const onCopyValue = () => {
    navigator.clipboard.writeText(value).then(() => {
      setBtnText('Value copied to clipboard');
      setTimeout(() => {
        setBtnText(copyImageLabel);
      }, 2000);
    });
  };
  return <button onClick={onCopyValue}> {btnText} </button>;
};

export default LargeDisplayValueHandler;
