import { action, computed, entries, makeObservable, observable, reaction } from 'mobx';
import { Element } from 'slimdom';
import { ControlType, IControlDefinition } from '../Definitions/IControlDefinition';
import { IItem } from '../Definitions/IItem';
import { DataType } from '../XForms/Types/TypeDefinitionFactory';
import Bind from './Bind';
import Itemset from './Itemset';
import { ValueOfInterest } from './ValueOfInterest';

export const otherValueAttribute = 'otherValue';

export default class Control<TDetail = any> {
  private readonly _bind: Bind;

  private _itemset: Itemset | null;

  private _touched = false;

  constructor(
    private readonly controlDefinition: IControlDefinition,
    bind: Bind,
    private readonly iterationId?: string,
  ) {
    makeObservable<
      Control,
      | '_bind'
      | '_itemset'
      | '_touched'
      | '_updatedSinceLastAutosave'
      | '_labelInfo'
      | '_labelInfoClass'
      | 'setItemset'
    >(this, {
      _bind: observable,
      _itemset: observable,
      _touched: observable,
      dataType: computed,
      value: computed,
      otherValue: computed,
      clearValue: action,
      updateValue: action,
      updateValueAsXml: action,
      updateOtherValue: action,
      _updatedSinceLastAutosave: observable,
      updatedSinceLastAutosave: computed,
      markAsNotUpdatedSinceLastAutosave: action,
      itemset: computed,
      touched: computed,
      _labelInfo: observable,
      _labelInfoClass: observable,
      labelInfo: computed,
      labelInfoClass: computed,
      setLabelInfo: action,
      markAsTouched: action,
      markAsUntouched: action,
      validate: action,
      isRequired: computed,
      isReadOnly: computed,
      isRelevant: computed,
      filterGroups: computed,
      valuesOfInterest: computed,
      isValid: computed,
      displayErrorMessage: computed,
      validationError: computed,
      setItemsetItems: action,
      setItemset: action,
      additionalAttributes: computed,
      markAsBadInput: action,
      clearBadInputFlag: action,
    });

    this._bind = bind;
    this._itemset = this.controlDefinition.itemset
      ? new Itemset(bind, this.controlDefinition.itemset)
      : null;

    reaction(
      () => this.value,
      () => {
        if (this._touched) {
          this.validate();
        }

        this._updatedSinceLastAutosave = true;
      },
      {
        name: 'Reacting to value change on ' + this.controlDefinition.id,
        fireImmediately: false,
      },
    );

    reaction(
      () => this.otherValue,
      () => {
        if (this._touched) {
          this.validate();
        }

        this._updatedSinceLastAutosave = true;
      },
      {
        name: 'Reacting to otherValue change for control ' + this.controlDefinition.id,
        fireImmediately: false,
      },
    );

    reaction(
      () => entries(this.additionalAttributes),
      () => {
        if (this._touched) {
          this.validate();
        }

        this._updatedSinceLastAutosave = true;
      },
      {
        name: 'Reacting to additionalAttributes change for control ' + this.controlDefinition.id,
        fireImmediately: false,
      },
    );

    if (this.isLazyControl) {
      reaction(
        () => this.filterGroups,
        () => {
          this.clearValue();
          this.markAsUntouched();
        },
      );
    }
  }

  get id(): string {
    return this.controlDefinition.id;
  }

  //This will return a unique id in the form (including repeat iterations)
  get uniqueId(): string {
    if (this.iterationId) {
      return `${this.iterationId}-${this.id}`;
    }
    return this.id;
  }

  get describedById(): string {
    return `${this.uniqueId}-description`;
  }

  get controlType(): ControlType {
    return this.controlDefinition.type;
  }

  get dataType(): DataType {
    return this._bind.dataType;
  }

  get label(): string {
    return this.controlDefinition.label;
  }

  get hint(): string {
    return this.controlDefinition.hint;
  }

  get helpText(): string {
    return this.controlDefinition.help;
  }

  get isHiddenFromUi(): boolean {
    return this.controlDefinition.isHiddenFromUi;
  }

  get classes(): string {
    return this.controlDefinition.classes;
  }

  get displayName(): string {
    return this._bind.displayName;
  }

  get value(): string {
    return this._bind.value;
  }

  get valueAsXML(): Element | null {
    return this._bind.valueAsXML;
  }

  get otherValue(): string {
    return this.additionalAttributes.get(otherValueAttribute) ?? '';
  }

  public clearValue(): void {
    this._bind.clearValue();
    this.clearOtherValue();
  }

  public updateValue(value: string): void {
    this._bind.updateValue(value);
  }

  public markAsBadInput(): void {
    this._bind.markAsBadInput();
  }

  public clearBadInputFlag(): void {
    this._bind.clearBadInputFlag();
  }

  public updateValueAsXml(value: Element, includeRootNode: boolean): void {
    this._bind.updateValueAsXml(value, includeRootNode);
  }

  public updateOtherValue(otherValue: string) {
    if (otherValue === '') {
      this.clearOtherValue();
    } else {
      this.additionalAttributes.set(otherValueAttribute, otherValue);
    }
  }

  private clearOtherValue() {
    this.additionalAttributes.delete(otherValueAttribute);
  }

  get additionalAttributes() {
    return this._bind.additionalAttributes;
  }

  private _updatedSinceLastAutosave = false;

  public get updatedSinceLastAutosave(): boolean {
    return this._updatedSinceLastAutosave;
  }
  public markAsNotUpdatedSinceLastAutosave() {
    this._updatedSinceLastAutosave = false;
  }

  get detail(): TDetail {
    return this.controlDefinition.detail;
  }

  get itemset(): Itemset | null {
    return this._itemset;
  }

  get isWithOther(): boolean {
    return this.controlDefinition.isWithOther;
  }

  public get touched() {
    return this._touched;
  }

  private _labelInfo = '';

  private _labelInfoClass = '';

  public get labelInfo(): string {
    return this._labelInfo;
  }

  public get labelInfoClass(): string {
    return this._labelInfoClass;
  }

  public setLabelInfo(text: string, className: string) {
    this._labelInfo = text;
    this._labelInfoClass = className;
  }

  public markAsTouched(): void {
    this._touched = true;
    this.validate();
  }

  public markAsUntouched(): void {
    this._touched = false;
    this._bind.validationError = '';
  }

  public validate() {
    this._touched = true;
    this._bind.validate();
  }

  get isRequired(): boolean {
    return this._bind.isRequired;
  }

  get isReadOnly(): boolean {
    return this._bind.isReadOnly;
  }

  get isRelevant(): boolean {
    return this._bind.isRelevant;
  }

  get filterGroups(): string[] | null {
    return this._bind.filterGroups;
  }

  get valuesOfInterest(): ValueOfInterest[] {
    return this._bind.valuesOfInterest;
  }

  get isValid(): boolean {
    return this._bind.isValid;
  }

  get displayErrorMessage(): boolean {
    return this.touched && this.isValid === false;
  }

  get validationError(): string {
    return this._bind.validationError;
  }

  get isLazyControl(): boolean {
    const lazyControlTypes: ControlType[] = ['lazy-select', 'lazy-multiselect'];
    return lazyControlTypes.includes(this.controlType);
  }

  public setItemsetItems(items: IItem[]) {
    this.setItemset(new Itemset(this._bind, items));
  }

  private setItemset(itemset: Itemset): void {
    this._itemset = itemset;
  }

  public hasRequestInFlight = false;
}
