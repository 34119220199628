import * as React from 'react';
import { ReactRenderer } from 'xml-renderer';
import LargeDisplayValueHandler from './LargeDisplayValueHandler';
import { tryGetLargeDisplayValue } from './LargeDisplayValueHandler.func';
import './XmlRender.css';

export const xmlRender = new ReactRenderer();
// text nodes
xmlRender.add('self::text()', ({ node }) => {
  if (node.parentElement && node.parentElement.hasAttribute('f4h:secret')) return '************';

  const largeDisplayValue = tryGetLargeDisplayValue(node.nodeValue);

  if (largeDisplayValue) return <LargeDisplayValueHandler value={largeDisplayValue.value} />;

  return <>{node.nodeValue}</>;
});

// element nodes
xmlRender.add('self::element()', ({ node, traverse }) => (
  <div className="xmlDebugDiv">
    <span className="xmlDebugElement">&lt;{node.nodeName}</span>
    <span className="xmlDebugAttrs">
      {Array.from((node as Element).attributes).map((attribute) => {
        return (
          <React.Fragment key={attribute.name}>
            <span className="xmlDebugAttKey"> {attribute.name}=</span>
            <span className="xmlDebugAttValue">{`"${attribute.value}"`}</span>
          </React.Fragment>
        );
      })}
    </span>
    <span className="xmlDebugElement">&gt;</span>
    {traverse()}
    <span className="xmlDebugElement">&lt;/{node.nodeName}&gt;</span>
  </div>
));
