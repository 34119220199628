export const tlTranslations = {
  tl: {
    metadata: {
      debugDisplayName: 'Tagalog',
    },
    translations: {
      add: 'Karagdagan',
      annotatedImage: {
        noAnnotations: 'Walang naitalang anotasyon',
      },
      booleanButtons: {
        no: 'Hindi',
        yes: 'Oo',
      },
      cancel: 'Kanselahin',
      clear: 'Malinaw',
      delete: 'Burahin',
      footer: {
        externalLinks: {
          accessibility: 'Paggamit',
          autoTranslate: 'Patakaran sa Auto Translate',
          privacy: 'Pribadong Patakaran',
        },
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner:
            'Ang form na ito ay inalis sa paggamit. Maaaring hindi tumpak ang impormasyong nilalaman nito.',
          dialog: {
            content:
              'Ang form na ito ay inalis sa paggamit. Maaaring hindi tumpak ang impormasyong nilalaman nito.',
            title: 'Inalis ang form',
          },
        },
      },
      freetextTranslations: {
        autotranslateLabel: 'Awtomatikong isinalin ang text noong {{dateTime}}',
        hideOriginalText: 'Itago ang orihinal na teksto',
        showOriginalText: 'Ipakita ang orihinal na teksto',
      },
      noOptionsFound: 'Walang nakitang mga opsyon.',
      otherControl: {
        other: 'Iba pa',
      },
      partialDate: {
        full: 'Buong petsa',
        monthYear: 'Buwan at taon',
        year: 'Taon',
      },
      repeat: {
        deleteRow: 'Tanggalin ang hilera',
        deleteRowConfirmation: {
          message:
            'Sigurado ka bang gusto mong tanggalin ang row? Ang gawaing ito ay hindi pwedeng baguhin.',
          title: 'Kumpirmahin ang pagtanggal',
        },
      },
      requestInFlightPopup: {
        heading: 'Natitirang kahilingan sa paglipad',
        message:
          'Hindi makapagpatuloy hanggang sa makumpleto ang kasalukuyang kahilingan. Pakisubukang muli sa ilang sandali.',
      },
      rotate: 'Ibaligtad',
      splitDate: {
        day: 'Araw',
        month: 'Buwan',
        year: 'Taon',
      },
      submissionFailedPopup: {
        heading: 'Nabigo ang pagsusumite',
        message:
          'Nagkaroon ng error habang isinusumite ang form. Suriin ang iyong koneksyon at subukang muli.',
      },
      validationPopup: {
        heading: 'mga mali sa pag papatunay',
        message:
          'Ang form ay naglalaman ng mga mali sa pagpapatunay. Mangyaring itama ang anumang mga di-wastong patlang',
      },
      wizard: {
        nextStep: 'Susunod na hakbang',
        previousStep: 'Nakaraang hakbang',
        submit: 'Ipasa',
      },
    },
  },
};
