import moment from 'moment';
import { ITypeDefinition } from './TypeDefinitionFactory';

export const Format = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const FormatWithoutTZ = 'YYYY-MM-DDTHH:mm:ss';
export default class DateTimeDefinition implements ITypeDefinition {
  formatToLocale(value: string) {
    return value;
  }

  parseToXmlValue(value: string) {
    return value;
  }

  isValid(value: string) {
    return value === '' || moment(value, Format, true).isValid();
  }
}
