import { Element } from 'slimdom';
import Bind from './Bind';
import Control from './Control';
import Row from './Row';

export default class MasterChildIteration {
  constructor(
    public readonly bind: Bind,
    public readonly element: Element,
    public readonly summaryRows: { controls: Control[] }[],
    public readonly rows: Row[],
  ) {}
}
