import axios from 'axios';

export const logError = async (error: Error) => {
  const message = [
    'URL: ' + window.location.href,
    'Message: ' + error.message,
    'Stack: ' + error.stack,
  ].join(' - ');

  await axios('/logs/clienterror', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({ message }),
  });
};
