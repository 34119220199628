import { itemSetValueSplitChar, otherValueIdentifier, parseItemSetValue } from '@airelogic/xpath';
import Enumerable from 'linq';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { IItem } from '../Definitions/IItem';
import Bind, { displayNameAttribute } from './Bind';
import { otherValueAttribute } from './Control';

const displayNameUserLocaleAttribute = 'displayNameUserLocale';

export default class Itemset {
  private readonly bind: Bind;

  constructor(
    bind: Bind,
    private readonly items: IItem[],
  ) {
    makeObservable<
      Itemset,
      'bind' | 'deselectValuesNotInItemset' | 'setDisplayNameForDefaultValues'
    >(this, {
      bind: observable,
      deselectValuesNotInItemset: action,
      hasStopConditionFired: computed,
      getFiredStopConditionText: computed,
      selectedItems: computed,
      setDisplayNameForDefaultValues: action,
      otherOptionIsSelected: computed,
    });

    this.bind = bind;
    this.setDisplayNameForDefaultValues();

    reaction(
      () => this.bind.filterGroups,
      () => this.deselectValuesNotInItemset(),
      {
        name: 'Reacting to filterGroups change for control ' + this.bind.id,
        fireImmediately: false,
      },
    );

    reaction(
      () => this.bind.value,
      () => {
        this.updateDisplayNames(this.selectedItems);
      },
      {
        name: 'Reacting to value change for control ' + this.bind.id,
        fireImmediately: false,
      },
    );
  }

  private setDisplayNameForDefaultValues() {
    if (this.bind.value !== '' && this.bind.displayName === '') {
      this.updateDisplayNames(this.selectedItems);
    }
  }

  public itemsForSelection(): IItem[] {
    return Enumerable.from(this.items)
      .where((item) => this.itemIsAvailableForSelection(item))
      .toArray();
  }

  private itemIsAvailableForSelection(item: IItem): boolean {
    if (this.itemIsDeprecatedAndSelected(item) === true) {
      return true;
    }

    if (item.deprecated === true) {
      return false;
    }

    if (item.filterGroups.length === 0) {
      return true;
    }

    if (this.bind.filterGroups === null) {
      return true;
    }

    return Enumerable.from(item.filterGroups).intersect(this.bind.filterGroups).any();
  }

  private itemIsDeprecatedAndSelected(item: IItem): boolean {
    return item.deprecated === true && Enumerable.from(this.selectedItems).contains(item.value);
  }

  public isValueSelected(value: string) {
    return this.selectedItems.some((item) => item === value);
  }

  private isExclusive(value: string): boolean {
    return this.items.some((item) => item.value === value && item.isExclusive === true);
  }

  public get otherOptionIsSelected(): boolean {
    return parseItemSetValue(this.bind.value).some((value) => value === otherValueIdentifier);
  }

  private deselectValuesNotInItemset() {
    const currentSelectedItems: string[] = this.selectedItems;

    this.selectedItems = Enumerable.from(this.itemsForSelection())
      .join(
        currentSelectedItems,
        (possibleOption) => possibleOption.value,
        (selectedOption) => selectedOption,
        (_, selectedOption) => selectedOption,
      )
      .toArray();
  }

  get selectedItems(): string[] {
    return parseItemSetValue(this.bind.value);
  }

  set selectedItems(items: string[]) {
    if (items.length === 0) {
      this.bind.clearValue();
      this.clearOtherValue();
      this.updateDisplayNames(items);
      return;
    }

    const newlyAddedItem = items[items.length - 1];

    if (this.isExclusive(newlyAddedItem)) {
      this.updateValue([newlyAddedItem]);
    } else {
      const nonExclusiveItems = items.filter(
        (selectedItem) => this.isExclusive(selectedItem) === false,
      );

      this.updateValue([...nonExclusiveItems]);
    }
  }

  private updateValue(selected: string[]) {
    const value = this.valueForSelection(selected);
    this.bind.updateValue(value);
    if (this.bind.isCalculated === false) {
      this.updateDisplayNames(selected);
    }
    if (this.otherOptionIsSelected === false) {
      this.clearOtherValue();
    }
  }

  private clearOtherValue = () => {
    this.bind.additionalAttributes.delete(otherValueAttribute);
  };

  private updateDisplayNames(selected: string[]) {
    const displayNames = this.displayNamesForValues(selected);

    if (displayNames.length === 0) {
      this.bind.additionalAttributes.set(displayNameAttribute, '');
      if (this.bind.additionalAttributes.get(displayNameUserLocaleAttribute)) {
        this.bind.additionalAttributes.set(displayNameUserLocaleAttribute, '');
      }
      return;
    }

    const tenantLocaleDisplayNames = displayNames.map((x) => x.displayNameTenantLocale);
    const userLocaleDisplayNames = displayNames.map((x) => x.displayNameUserLocale);
    const hasTenantLocales = tenantLocaleDisplayNames.every((x) => x !== '');

    if (hasTenantLocales) {
      this.bind.additionalAttributes.set(displayNameAttribute, tenantLocaleDisplayNames.join('~~'));
      this.bind.additionalAttributes.set(
        displayNameUserLocaleAttribute,
        userLocaleDisplayNames.join('~~'),
      );
    } else {
      this.bind.additionalAttributes.set(displayNameAttribute, userLocaleDisplayNames.join('~~'));
    }
  }

  private getStopConditionText(value: string): string {
    const item = this.items.filter((item) => item.value === value)[0];

    if (item && item.stopConditionText) {
      return item.stopConditionText;
    }

    return '<p class="card-text">The answer you have selected suggests that you may require more urgent attention.</p><p class="card-text">Please contact your GP directly or consider calling 111 (or 999 in an emergency).</p>';
  }

  get hasStopConditionFired(): boolean {
    return this.selectedItems.some((item) => this.isStopCondition(item));
  }

  get getFiredStopConditionText(): string | undefined {
    const item = this.selectedItems.find((item) => this.isStopCondition(item));
    return item ? this.getStopConditionText(item) : undefined;
  }

  private isStopCondition(value: string): boolean {
    return this.items.some((item) => item.value === value && item.isStopCondition === true);
  }

  private valueForSelection(selected: string[]): string {
    return selected.join(itemSetValueSplitChar);
  }

  private displayNamesForValues(selected: string[]) {
    return Enumerable.from(selected)
      .join(
        this.items,
        (valueItem) => valueItem,
        (item) => item.value,
        (_, item) => ({
          displayNameUserLocale: item.displayNameUserLocale,
          displayNameTenantLocale: item.displayNameTenantLocale,
        }),
      )
      .toArray();
  }
}
