import { Element } from 'slimdom';
import Bind from './Bind';
import Row from './Row';

export default class RepeatIteration {
  constructor(
    public readonly bind: Bind,
    public readonly element: Element,
    public readonly rows: Row[],
  ) {}

  get isMultiRow(): boolean {
    return this.rows.length > 1;
  }
}
