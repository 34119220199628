import {
  IDomFacade,
  evaluateXPath,
  evaluateXPathToBoolean,
  evaluateXPathToFirstNode,
  evaluateXPathToString,
  evaluateXPathToStrings,
  registerCustomXPathFunction,
} from '@airelogic/fontoxpath';
import { Node } from 'slimdom';
import { IInstanceResolver, registerAllCustomFunctions } from '../CustomXPathFunctions/Registry';

export interface IXPathFallbacks<T> {
  errorValue: T;
  defaultValue: T;
}
export interface IXPathEvaluator {
  instanceResolver: IInstanceResolver;
  evaluateXPathToFirstNode(xpath: string, context: Node, domFacade?: IDomFacade): Node | null;
  evaluateXPathToBoolean(xpath: string, context?: Node, domFacade?: IDomFacade): boolean;
  evaluateXPathToString(xpath: string, context?: Node, domFacade?: IDomFacade): string;
  evaluateXPathToStrings(xpath: string, context?: Node, domFacade?: IDomFacade): string[];
  evaluateXPath(xpath: string, context?: Node, domFacade?: IDomFacade): any;
  evaluateXPathToBooleanWithFallbacks(
    xpath: string | null,
    context: Node,
    fallbacks: IXPathFallbacks<boolean>,
  ): boolean;
  evaluateXPathToStringWithFallbacks(
    xpath: string | null,
    context: Node,
    fallbacks: IXPathFallbacks<string>,
  ): string;
}
class XPathEvaluator implements IXPathEvaluator {
  private current: Node | null;

  instanceResolver: IInstanceResolver;

  constructor() {
    registerAllCustomFunctions(
      registerCustomXPathFunction,
      () => this.instanceResolver,
      () => this.current,
    );
  }

  public evaluateXPathToFirstNode(
    xpath: string,
    context: Node,
    domFacade?: IDomFacade,
  ): Node | null {
    return this.evaluate<Node | null>(evaluateXPathToFirstNode, xpath, context, domFacade);
  }

  public evaluateXPathToBoolean(xpath: string, context?: Node, domFacade?: IDomFacade): boolean {
    return this.evaluate(evaluateXPathToBoolean, xpath, context, domFacade);
  }

  public evaluateXPathToString(xpath: string, context?: Node, domFacade?: IDomFacade): string {
    return this.evaluate(evaluateXPathToString, xpath, context, domFacade);
  }

  public evaluateXPathToStrings(xpath: string, context?: Node, domFacade?: IDomFacade): string[] {
    return this.evaluate(evaluateXPathToStrings, xpath, context, domFacade);
  }

  public evaluateXPath(xpath: string, context?: Node, domFacade?: IDomFacade) {
    return this.evaluate(evaluateXPath, xpath, context, domFacade);
  }

  private evaluate<T>(
    evaluationFunction: (xpath: string, context?: Node, domFacade?: IDomFacade) => T,
    xpath: string,
    context?: Node,
    domFacade?: IDomFacade,
  ): T {
    try {
      if (context) {
        this.current = context;
      }
      return evaluationFunction(xpath, context, domFacade);
    } finally {
      this.current = null;
    }
  }

  public evaluateXPathToBooleanWithFallbacks(
    xpath: string | null,
    context: Node,
    fallbacks: IXPathFallbacks<boolean>,
  ): boolean {
    return this.evaluateWithFallbacks(evaluateXPathToBoolean, xpath, context, fallbacks);
  }

  public evaluateXPathToStringWithFallbacks(
    xpath: string | null,
    context: Node,
    fallbacks: IXPathFallbacks<string>,
  ): string {
    return this.evaluateWithFallbacks(evaluateXPathToString, xpath, context, fallbacks);
  }

  private evaluateWithFallbacks<T>(
    evaluationFunction: (xpath: string, context?: Node) => T,
    xpath: string | null,
    context: Node,
    fallbacks: IXPathFallbacks<T>,
  ): T {
    if (xpath) {
      try {
        return this.evaluate(evaluationFunction, xpath, context);
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.includes('FORG0001')) {
            return fallbacks.errorValue;
          }
        }
        throw error;
      }
    } else {
      return fallbacks.defaultValue;
    }
  }
}

export const xPathEvaluator = new XPathEvaluator();
