import { isDocumentRtl } from '../../Utils';

const DocDirectionToggle = () => {
  const changeDirection = () => {
    const dir = isDocumentRtl() ? 'ltr' : 'rtl';
    const url = new URL(window.location.href);
    url.searchParams.set('dir', dir);
    window.location.assign(url.href);
  };

  return (
    <div>
      <button onClick={changeDirection}>Toggle document direction</button>
    </div>
  );
};

export default DocDirectionToggle;
