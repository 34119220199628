/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="./types/typings.d.ts" />
/// <reference path="./types/i18next.d.ts" />

import { configure } from 'mobx';
import { IFormDefinition, ThemeOptions } from './Definitions/IFormDefinition';
import Form from './Form';
import Container from './Layouts/Wizard/Container';
import Header from './Layouts/Wizard/Header';
import { isDocumentRtl } from './Utils';
import './i18n';
import i18n from './i18n';

configure({
  useProxies: 'always',
  enforceActions: 'observed',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // disableErrorBoundaries: true
});

export type { IFormDefinition as FormDefinition, ThemeOptions };

export { Form, Container as WizardContainer, Header as WizardHeader, i18n, isDocumentRtl };
