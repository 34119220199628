import { Element } from 'slimdom';
import { IRepeatDefinition } from '../Definitions/IRepeatDefinition';
import Bind from './Bind';
import RepeatIteration from './RepeatIteration';
import { Repeatable } from './Repeatable';
import Row from './Row';

export interface IHeadingDefinition {
  totalCellSpan: number;
  headings: IRepeatHeading[];
}

export interface IRepeatHeading {
  cellSpan: number;
  label: string;
  hint: string;
  helpText: string;
  couldBeRequired: boolean;
  describedById: string;
}

export default class Repeat extends Repeatable<IRepeatDefinition, RepeatIteration> {
  get id(): string {
    return this.definition.id;
  }

  get headingDefinition(): IHeadingDefinition | undefined {
    return this.isMultiRow
      ? undefined
      : {
          totalCellSpan: this.definition.rows[0].totalCellSpan,
          headings: this.definition.rows[0].cells.map((cell) => ({
            cellSpan: cell.cellSpan,
            label: cell.control ? cell.control.label : '',
            hint: cell.control ? cell.control.hint : '',
            helpText: cell.control ? cell.control.help : '',
            couldBeRequired: cell.control
              ? this.iterationChildBinds.find((bind) => bind.id === cell.control!.id)!
                  .couldBeRequired
              : false,
            describedById: cell.control ? `${cell.control.id}-hint` : '',
          })),
        };
  }

  protected createIteration(
    iterationBind: Bind,
    iterationElement: Element,
    rows: Row[],
  ): RepeatIteration {
    return new RepeatIteration(iterationBind, iterationElement, rows);
  }
}
