export const ruTranslations = {
  //Russian
  ru: {
    metadata: {
      debugDisplayName: 'Russian',
    },
    translations: {
      delete: 'Удалить',
      cancel: 'Отмена',
      add: 'Добавить',
      clear: 'Очистить',
      rotate: 'Повернуть',
      wizard: {
        nextStep: 'Далее',
        previousStep: 'Назад',
        submit: 'Отправить',
      },
      validationPopup: {
        heading: 'Ошибку при валидации',
        message: 'В форме присутствуют. Пожалуйста исправьте все поля с ошибками.',
      },
      submissionFailedPopup: {
        heading: 'Отправка не удалась',
        message: 'При отправке формы произошла ошибка. Проверьте подключение и повторите попытку.',
      },
      booleanButtons: {
        yes: 'Да',
        no: 'Нет',
      },
      otherControl: {
        other: 'Другое',
      },
      splitDate: {
        day: 'День',
        month: 'Месяц',
        year: 'Год',
      },
      formWarning: {
        FORM_WITHDRAWN: {
          banner:
            'Данная форма изъята из употребления. Содержащаяся в ней информация может быть неточной.',
          dialog: {
            title: 'Форма отозвана',
            content:
              'Данная форма изъята из употребления. Содержащаяся в ней информация может быть неточной.',
          },
        },
      },
      annotatedImage: {
        noAnnotations: 'Аннотации не записаны',
      },
      repeat: {
        deleteRow: 'Удалить строку',
        deleteRowConfirmation: {
          title: 'Подтвердить удаление',
          message: 'Вы уверены, что хотите удалить строку? Это действие не может быть отменено.',
        },
      },
    },
  },
};
