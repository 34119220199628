import moment from 'moment';
import { otherValueIdentifier } from '../..';

import { parseItemSetValue } from '../ItemsetHelpers';
import { IXPathRegister } from './Registry';

const registerMultiSelectValue = (register: IXPathRegister) => {
  register('nf:multi-select-values', ['xs:string'], 'xs:string*', (context: any, value: string) =>
    parseItemSetValue(value),
  );
};

const registerValidNHSNumberFunction = (register: IXPathRegister) => {
  register('nf:isValidNHSNumber', ['xs:string'], 'xs:boolean', (context: any, NHSNumber: string) =>
    isValidNHSNumber(NHSNumber),
  );
};

const isValidNHSNumber = (nhsNumber: string): boolean => {
  nhsNumber = nhsNumber.split(' ').join('');
  const nhsNumberInt = parseInt(nhsNumber);
  if (isNaN(nhsNumberInt)) return false;
  //See: https://en.wikipedia.org/wiki/NHS_number
  let sum = 0;
  if (nhsNumber.length !== 10) return false;
  for (let i = 10; i >= 2; i--) {
    sum += i * parseInt(nhsNumber.charAt(10 - i));
  }
  const checksum = 11 - (sum % 11);
  const lastInt = parseInt(nhsNumber.charAt(9));

  return lastInt === checksum || (checksum === 11 && lastInt === 0);
};

const countDecimals = (value: number): number => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

const registerMaxFractionalDigitsFunction = (register: IXPathRegister) =>
  register(
    'xxf:fraction-digits',
    ['xs:decimal?', 'xs:integer'],
    'xs:boolean',
    (context: any, value: number, max: number) => {
      if (!value) return true;
      else return countDecimals(value) <= max;
    },
  );

const registerDateAddFunction = (register: IXPathRegister) =>
  register(
    'nf:date-add',
    ['xs:string?', 'xs:string', 'xs:integer'],
    'xs:string?',
    (context: any, date: string | null, unit: string, amount: number) => {
      switch (unit) {
        case 'days':
        case 'd':
        case 'months':
        case 'M':
        case 'years':
        case 'y':
        case 'seconds':
        case 's':
        case 'minutes':
        case 'm':
        case 'hours':
        case 'h':
          break;
        default:
          throw Error(`Invalid interval ${unit}`);
      }
      const dateAsMoment = moment(date, true);
      if (dateAsMoment.isValid() === false) {
        return null;
      }
      return moment(date).add(amount, unit).toISOString(true);
    },
  );

const registerOtherValueFunction = (register: IXPathRegister) =>
  register('nf:other-value', [], 'xs:string', () => {
    return otherValueIdentifier;
  });

export const registerCustomFunctions = (xpathRegister: IXPathRegister) => {
  registerValidNHSNumberFunction(xpathRegister);
  registerMaxFractionalDigitsFunction(xpathRegister);
  registerMultiSelectValue(xpathRegister);
  registerDateAddFunction(xpathRegister);
  registerOtherValueFunction(xpathRegister);
};
