import { ThemeOptions } from '../Definitions/IFormDefinition';
import AireLogicTheme from './AireLogic';
import AireLogicCompactTheme from './AireLogicCompact';
import { FormTheme } from './FormTheme';
import GDSTheme from './GDS';
import { getContrastingColor } from './utils/themeUtils';

export function loadTheme(themeOptions: ThemeOptions): FormTheme {
  switch (themeOptions.name) {
    case 'Custom':
      return AireLogicTheme(
        themeOptions.primaryColor,
        getContrastingColor(themeOptions.primaryColor),
      );
    case 'GDS':
      return GDSTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
    case 'DefaultCompact':
      return AireLogicCompactTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
    default:
      return AireLogicTheme(themeOptions.primaryColor, themeOptions.primaryTextColor);
  }
}
