import { createXPathEvaluator } from '@airelogic/xpath';
import { action } from 'mobx';
import Form from '../Components/Form';
import { IFormDefinition } from '../Definitions/IFormDefinition';
import NavigateParams from '../Persistence/NavigateParams';
import PersistenceHandler from '../Persistence/PersistenceHandler';
import { composeSubmission } from '../Persistence/SubmissionComposer';
import { SubmissionService, defaultSubmissionService } from '../Persistence/SubmissionService';
import SuccessActionResolver from '../Persistence/SuccessActionResolver';
import { convertBinds } from './BindConverter';
import { convertBuildingBlocks } from './BuildingBlockConverter';
import { convertInstances } from './InstanceConverter';
import { convertPages } from './PageConverter';

export const convertForm = action(
  (
    formDefinition: IFormDefinition,
    submissionService: SubmissionService = defaultSubmissionService,
  ) => {
    const instanceStore = convertInstances(formDefinition);
    const xPathEvaluator = createXPathEvaluator(instanceStore.resolve);

    const bindStore = convertBinds(instanceStore, xPathEvaluator, formDefinition.buildingBlocks);

    const buildingBlocks = convertBuildingBlocks(
      instanceStore,
      bindStore,
      formDefinition.buildingBlocks,
    );
    const pages = convertPages(buildingBlocks, formDefinition.pages);

    const form = new Form(
      formDefinition.title,
      formDefinition.warningCode,
      formDefinition.viewMode,
      pages,
      formDefinition,
    );

    const persistenceHandler = new PersistenceHandler(
      formDefinition.persistence,
      {
        validate: form.validate,
        markControlsAsNotUpdated: form.markControlsAsNotUpdated,
        updatedSinceLastAutosave: () => form.updatedSinceLastAutosave,
        hasControlsWithRequestInFlight: () => form.hasControlsWithRequestInFlight,
      },
      (recaptchaToken?: string) =>
        composeSubmission(formDefinition.buildingBlocks, instanceStore, recaptchaToken),
      submissionService,
      new SuccessActionResolver(
        formDefinition.successAction,
        new NavigateParams(window.location.search),
        (xpath: string) => xPathEvaluator.evaluateXPathToBoolean(xpath),
      ),
    );

    return {
      form,
      instanceStore,
      persistenceHandler,
    };
  },
);
